import React from 'react'
import logoEmail from '../../assets/img/components/icon_email.svg'
import logoPhone from '../../assets/img/components/phone.svg'
import { Row } from 'react-bootstrap'
export const HeaderPrivacy = ({ title, subtitle, phone, email }) => {
  return (
   <>
       {title ?
        <div className="container">
          <Row className='content-vide-description-privacy' xl={10} md={10} xs={12}>
            <h2 className='col-xl-12 col-xs-12 title-compont-video'>{title}</h2>
            <p className='col-xl-12 col-xs-12  subtitle-compont-video' >{subtitle ? subtitle : ''}</p>
            <p className='email-header  email-compont-video '>
              {email ?
                (
                  <>
                    <img src={logoEmail} alt="logo_delfosti" className=' logo-email-header' /><a href="mailto:contacto@delfosti.com" className='noneDecoder'>{email}</a></>
                ) :
                ""
              }
            </p>

            <p className='email-header phone-compont-video'>
              {phone ?
                (
                  <>
                    <img src={logoPhone} alt="logo_delfosti" className=' logo-phone-header' /><a href={`tel:${phone}`} className='noneDecoder'>{phone}</a></>
                ) :
                ""
              }
            </p>


          </Row>
        </div>
        : ''}</>
  )
}
