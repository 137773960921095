import image1 from '../assets/Portafolio/1A.png'
import image2 from '../assets/Portafolio/2A.png'
import image3 from '../assets/Portafolio/3A.png'
import image4 from '../assets/Portafolio/4A.png'
import image5 from '../assets/Portafolio/5A.png'
import image6 from '../assets/Portafolio/6A.png'
import image7 from '../assets/Portafolio/7A.png'
import image8 from '../assets/Portafolio/8A.png'
import image9 from '../assets/Portafolio/9A.png'
import image10 from '../assets/Portafolio/10A.png'

export const dataImg =[
  {
    src:image1,
    thumbnail: 'ruta_de_la_miniatura_1.jpg',
    thumbnailWidth: 400,
    thumbnailHeight: 300,
    caption: 'Imagen 2',
   // Alto de la miniatura
  },
  {
    src: image2,
    thumbnail: 'ruta_de_la_miniatura_2.jpg',
    thumbnailWidth: 400,
    thumbnailHeight: 300,
    caption: 'Imagen 1',
  },
  {
    src:image3,
    thumbnail: 'ruta_de_la_miniatura_1.jpg',
    thumbnailWidth: 300,
    thumbnailHeight: 200,
    caption: 'Imagen 3',
   // Alto de la miniatura
  },
  {
    src: image4,
    thumbnail: 'ruta_de_la_miniatura_2.jpg',
  },
  {
    src:image5,
    thumbnail: 'ruta_de_la_miniatura_1.jpg',
   // Alto de la miniatura
  },
  {
    src: image6,
    thumbnail: 'ruta_de_la_miniatura_2.jpg',
  },
  {
    src: image7,
    thumbnail: 'ruta_de_la_miniatura_2.jpg',
  },
  {
    src: image8,
    thumbnail: 'ruta_de_la_miniatura_2.jpg',
  },
  {
    src: image9,
    thumbnail: 'ruta_de_la_miniatura_2.jpg',
  },
  {
    src: image10,
    thumbnail: 'ruta_de_la_miniatura_2.jpg',
  }
]