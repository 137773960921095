import React, { useState, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';

export const BlockCircle = ({ data }) => {
  const [hoveredIndex, setHoveredIndex] = useState(0);
  const [isMouseInside, setIsMouseInside] = useState(false);

  useEffect(() => {
    const hoverTimer = setInterval(() => {
      if (!isMouseInside) {
        setHoveredIndex((prevIndex) => (prevIndex + 1) % data.length);
      }
    }, 2000);

    return () => clearInterval(hoverTimer);
  }, [data.length, isMouseInside]);

  return (
    <React.Fragment>
      <div className='block-circles-component'id='block-circles-component' >
    
      <div className='container'>
      <Row className='row text-center' xl={12} md={12} xs={12}>
          {data.map((circle, index) => (
            <Col key={index} xl={4} md={12} xs={12}className='col-circle '>
              <div
                className={`circle ${index === hoveredIndex ? 'active' : ''}`}
                onMouseEnter={() => {
                  setIsMouseInside(true);
                  setHoveredIndex(index);
                }}
                onMouseLeave={() => setIsMouseInside(false)}
              >
                <div className='content-description-ciircle'>
                  <h1 className='tle-circle'>{circle.title}</h1>
                  <span className='ds-circle'>{circle.description}</span>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </div>
   
      </div>
    
    </React.Fragment>
  );
};
