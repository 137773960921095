import img1 from "../assets/img/grup.png";
import img2 from "../assets/img/grup2.png";
import img3 from "../assets/img/grup3.png";

import {
  imgT1,
  imgT2,
  imgT3,
  imgT4,
  imgT5,
  imgT6,
  imgT7,
  imgT8,
  imgT9,
  imgT10,
} from "./image/imgTestimony.ts";

export const dataApiWebsiteAbout = {
  code: 200,
  status: "success",
  message: "Confirmed",
  data: {
    type: "page",
    id: "About",
    attributes: {
      "block-banner": {
        type: "block",
        id: "banner",
        attributes: {
          title: "Somos #TeamDelfosti",
          description:
            "Transformamos ideas en realidades tecnológicas, explorando nuevas formas de ver el mundo.",
          multimedia: {
            //esto no es una imagen es una libreria para traer spline
            type: "video",
            url: "",
            alt: "",
            title: "",
            embed: "",
          },
        },
      },
      "block-teams-history": {
        type: "block",
        attributes: {
          title: "Nuestra historia",
          description:
            "Delfosti surge producto de un sueño compartido por un grupo de amigos apasionados por la tecnología, anhelando crear un ambiente innovador, donde prime la excelencia y que la energía positiva sea el motor que lo impulse. Hoy, somos una empresa tecnológica que se enfoca en la necesidad del cliente, bajo el compromiso y la confianza de que nuestras propuestas y soluciones generen un valor comercial real.",
          multimedia: [
            {
              type: "image",
              url: img1,
              alt: "",
              title: "",
              embed: "",
            },
            {
              type: "image",
              url: img2,
              alt: "",
              title: "",
              embed: "",
            },
            {
              type: "image",
              url: img3,
              alt: "",
              title: "",
              embed: "",
            },
          ],
        },
      },
      "block-teams-carrousel": {
        type: "block",
        id: "teams-carrousel",
        attributes: {
          title: "Conoce al equipo",
          items: [
            {
              multimedia: {
                type: "",
                url: imgT1,
                alt: "",
                title: "",
                embed: "",
              },
              jobs: "Chief Technology & Product Officer",
              name: "Luis Gabriel",
              testimony: "Estoy muy orgulloso de ser parte de este equipo y de todo lo que hemos crecido y aprendido. Un día en Delfosti es un día más para innovar e imaginar ideas para hacer más simple la vida de los clientes. Somos un equipo excepcional, con un espíritu emprendedor, atrevido, divertido y alegre."
            },
            {
              multimedia: {
                type: "",
                url: imgT2,
                alt: "",
                title: "",
                embed: "",
              },
              jobs: "Chief Commercial Officer",
              name: "Stefano Spodek",
              testimony: "Cada día en Delfosti es un reto con cada experiencia nueva que tenemos con nuestros clientes. Tenemos el espíritu de 'Todo lo podemos'. No nos regimos por lo que una corporación nos dice cómo tenemos que hacer las cosas. Nosotros creamos las formas en cómo se hacen. Somos un equipo unido por el compromiso, la pasión y el deseo de alcanzar metas extraordinarias."
            },
            {
              multimedia: {
                type: "",
                url: imgT3,
                alt: "",
                title: "",
                embed: "",
              },
              jobs: "Chief Executive Officer",
              name: "Luis Arévalo",
              testimony: "Somos un equipo apasionado, trabajando incansablemente para metas comunes. El compromiso, la adaptabilidad y el enfoque decidido nos llevan al éxito. Mirando al futuro, mantengamos la determinación y ambición que nos han guiado hasta aquí."
            },
            {
              multimedia: {
                type: "",
                url: imgT4,
                alt: "",
                title: "",
                embed: "",
              },
              jobs: "Sales Consultant Manager",
              name: "Paulo Garrido Lecca",
              testimony: "Cuando se tuvieron las primeras reuniones de Delfos, jamás imaginamos estar donde estamos hoy, y menos en tan poco tiempo. Ver el crecimiento de la empresa en este periodo me lleva a la conclusión de que es 100% gracias a su gente.  Para mí, es un regalo ir todos los días a la oficina para hacer más grande este sueño. Somos una empresa que apunta alto y no tiene miedo a los retos. Tenemos ese espíritu de lograrlo todo."
            },
            {
              multimedia: {
                type: "",
                url: imgT5,
                alt: "",
                title: "",
                embed: "",
              },
              jobs: "Technical Team Leader",
              name: "Marco Torres",
              testimony: "Lo que más me gusta de ser parte del #TeamDelfosti es la oportunidad constante de aprender y crecer junto a colegas apasionados por los desafíos tecnológicos. Aquí, el espíritu de la empresa se siente como una energía contagiante de pasión por la tecnología y la innovación."
            },
            {
              multimedia: {
                type: "",
                url: imgT6,
                alt: "",
                title: "",
                embed: "",
              },
              jobs: "Technical Team Leader",
              name: "Diego Lozano",
              testimony: "Delfosti es una empresa que te permite crecer e innovar con tecnologías de vanguardia, así como brindarte un excelente ambiente laboral en el que se incentiva la mejora continua.  Estar aquí me ha dado la oportunidad de crecer como ingeniero y como líder. Me ha ayudado a potenciar tanto mis habilidades técnicas como mis habilidades blandas liderando equipos diversos en proyectos llenos de retos y con enfoques completamente diferentes."
            },
            {
              multimedia: {
                type: "",
                url: imgT7,
                alt: "",
                title: "",
                embed: "",
              },
              jobs: "Product Owner Specialist",
              name: "Kattia Mendoza",
              testimony: "Delfosti ha sido clave en mi desarrollo profesional, ofreciéndome experiencias variadas con mis clientes asignados, desde pasarelas de pago, análisis de datos, proyectos comerciales y staffing. La interacción con un equipo experimentado ha sido importante para mi crecimiento, creando un ambiente colaborativo donde aprendemos mutuamente. Estoy agradecida por la oportunidad de contribuir al éxito de Delfosti y ansiosa por seguir creciendo."
            },
            {
              multimedia: {
                type: "",
                url: imgT8,
                alt: "",
                title: "",
                embed: "",
              },
              jobs: "UX/UI Designer Specialist",
              name: "Melany Peña Skinner",
              testimony: "¡Mi equipo es asombroso! Está lleno de personas extraordinarias que no solo son increíblemente talentosas, sino que también tienen una energía contagiosa que hace que trabajar con ellos sea una experiencia única. La positividad y el entusiasmo son como nuestro combustible diario, y si necesitas ayuda siempre habrá alguien dispuesto a ofrecerla. Nos impulsa la pasión, la dedicación y esa chispa de alegría que hace que cada día sea diferente."
            },
            {
              multimedia: {
                type: "",
                url: imgT9,
                alt: "",
                title: "",
                embed: "",
              },
              jobs: "Administration and Finance Manager",
              name: "Hosni Valdivia",
              testimony: "El espíritu que se vive en Delfosti es de camaradería y unión. Venir a trabajar es una experiencia nueva cada día. Observo mucho apoyo entre los colaboradores, apoyo lleno de alegría y optimismo junto con la excelencia que nos caracteriza.  Me gusta la integridad y honestidad de mis compañeros, me permite saber que cuento con un apoyo que va más allá de los deberes labores."
            },
            {
              multimedia: {
                type: "",
                url: imgT10,
                alt: "",
                title: "",
                embed: "",
              },
              jobs: "Coordinadora Pet Friendly",
              name: "Ninfa Torres",
              testimony: "Woof woof woof woof woof woof woof woof woof woof woof woof woof woof woof woof woof woof woof woof woof woof woof woof woof woof woof woof woof. Woof woof woof woof woof woof woof woof woof woof woof woof woof woof woof woof woof woof woof woof woof woof woof woof woof woof woof woof woof woof woof woof."
            }
          ]
        }
      }
    }
  },
  included: [
    {
      type: "block",
      id: "menu",
      attributes: {
        items: [
          {
            text: "Contáctenos",
            url: "",
          },
          {
            text: "Servicios",
            url: "",
          },
          {
            text: "Clientes",
            url: "",
          },
          {
            text: "Portafolio",
            url: "",
          },
          {
            text: "Conócenos",
            url: "",
          },
          {
            text: "Home",
            url: "",
            multimedia: {
              type: "image",
              url: "",
              alt: "",
              title: "",
              embed: "",
            },
          },
        ],
      },
    },
    {
      type: "block",
      id: "menu-mobile",
      attributes: {
        items: [
          {
            text: "Contáctenos",
            url: "",
          },
          {
            text: "Servicios",
            url: "",
          },
          {
            text: "Portafolio",
            url: "",
          },
          {
            text: "Conócenos",
            url: "",
          },
          {
            text: "Home",
            url: "",
            multimedia: {
              type: "image",
              url: "",
              alt: "",
              title: "",
              embed: "",
            },
          },
        ],
        "footer-menu": {
          title: "Escríbenos",
          email: "contacto@delfosti.com",
          link: {
            text: "Siguenos en",
            url: "https://www.linkedin.com/company/delfosti/mycompany/",
          },
          address: "Calle José Gálvez 692, Miraflores",
        },
      },
    },
    {
      type: "block",
      id: "informative",
      attributes: {
        title: "Trabajemos juntos",
        description:
          "Si estás buscando un alidado para hacer realidad tu proyecto, estamos para ayudarte.",
        link: {
          text: "Quiero saber más",
          url: "",
        },
      },
    },
    {
      type: "block",
      id: "linkedin",
      attributes: {
        title: "Siguenos en LinkedIn",
        description: "Conoce las novedades y oportunidades laborales ",
        link: {
          multimedia: {
            type: "image",
            url: "",
            alt: "",
            title: "",
            embed: "",
          },
          url: "",
        },
      },
    },
    {
      type: "block",
      id: "footer",
      attributes: {
        title: "Comunícate con nosotros",
        description:
          "Escríbenos para comenzar el proyecto que tienes en mente.",
        email: "contacto@delfosti.com",
        link: {
          text: "Siguenos en ",
          url: "https://www.linkedin.com/company/delfosti/mycompany/",
        },
        multimedia: {
          type: "image",
          url: "",
          alt: "",
          title: "",
          embed: "",
          link: "",
        },
        copyright: "© 2023 Delfosti. Todos los derechos reservados.",
      },
    },
  ],
  meta: {
    seo_title: "Delfosti",
    seo_description:
      "Loremp ipsTransformamos tu empresa a través de la innovación para ofrecerte soluciones increíbles.",
    seo_keywords:
      "Delfosti, Desarrollo de Software, Soluciones Tecnológicas, Innovación Digital, Transformación Digital, Desarrollo de Aplicaciones, Soluciones Personalizadas, Ingeniería de Software, Consultoría Tecnológica, Programación Avanzada, Desarrollo Ágil, Soluciones Empresariales, Arquitectura de Software, Desarrollo a Medida, Experiencia de Usuario (UX), Soluciones Cloud, Integración de Sistemas, Consultoría en TI, Automatización de Procesos, Tecnologías Emergentes, Estrategias Digitales.",
    seo_url: "https://delfosti.com",
  },
  links: {},
};
