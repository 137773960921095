import React from "react";
import { Row } from "react-bootstrap";
import { Link } from "react-router-dom";
export const WorkingDelfos = ({ data }) => {
  return (
    <div
      className="container content-box content-box-working-delfos"
      id="component-box-working-delfo"
    >
      <Row className="" xl={12} xxl={12} xs={12}>
        <h1 className="title-box-overlay col-12">{data.title}</h1>
        <p className="description-circle col-5 col-md-5 col-xs-12">
          {data.description}
        </p>
        <div>
          <button className='btn-contact-us btn-light btn'>
            <a href="mailto:contacto@delfosti.com" className='text-decore-btn' styles="text-decoration: none;">Quiero saber más</a>
          </button>
        </div>
        {/* ----------------comentado Boton conocenos--------------------- */}

        {/*  <Link to={'/contacto'}>
        <button  className='btn-contact-us btn-light btn' >{data.link.text}</button>
      </Link> */}
      </Row>
    </div>
  );
};
