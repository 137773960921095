import React, { useEffect, useState } from 'react'
import { Col, Row, Spinner } from 'react-bootstrap'
import { Header } from '../components/Header'
import { LoadPage } from '../components/LoadPage'
import { HeaderPrivacy } from '../components/WorkingDelfos/HeaderPrivacy'
import { Footer } from '../components/Footer'
import { CursorDefault } from '../components/Cursor/CursorDefault'

export const PrivacyPolicy = () => {
  const [LoadCanva, setLoadCanva] = useState(true)
  const [isFirstLoad, setIsfirstLoad] = useState(true)
  useEffect(() => {
    setInterval(() => {
      setIsfirstLoad(false)
    }, 5000);
    setInterval(() => {

      setLoadCanva(false)
    }, 5500);
    // console.log(isFirstLoad)


  },)
  return (
    <>
    <CursorDefault />
      {
        isFirstLoad ? <LoadPage textTitle={'Políticas de privacidad'} /> :
          <>
            <div  className='content-block-header-blue-privacity' id='content-block-header-blue-privacity'>
              <div className="content-components-headers">
                <Header
                />
                <div className={`content-transition ${LoadCanva ? 'loading' : ''}`}>
                  {LoadCanva ? (
                    <div className='text-center content-load-spninner'>
                      <Spinner animation='grow' variant='light' />
                      <Spinner animation='grow' variant='light' />
                      <Spinner animation='grow' variant='light' />
                    </div>
                  ) :
                    <>
                      <HeaderPrivacy
                        title={'Políticas de privacidad'}
                        subtitle={'Última actualización: 20 de Setiembre 2023'} />
                    </>}
                </div>
              </div>
            </div>
            <div className='block-content-privacy-policy' id='block-content-privacy-policy'>
              <div className="container justify-content-center">
                <Row xxl={10} xl={10} md={12} xs={12} className='block-content-privacy-policy'>
                  <Col  xxl={10} xl={10} md={12} xs={12} className='card-description-privacy'>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Optio culpa dolorem, porro esse aliquid sit repudiandae natus totam, eos fugit odit a est hic eaque accusantium. Id culpa minus veniam?</Col>
               <Col  xxl={10} xl={10} md={12} xs={12} className="content-items-privacy">
               <h2 className='card-title-privacy'>
                    TITLE
                  </h2>
                  <div className='card-description-privacy'>
                    Lorem ipsum dolor sit, amet consectetur adipisicing elit. Dolorum neque porro aut animi iusto, error in doloribus necessitatibus expedita voluptatibus molestias architecto enim quibusdam excepturi fuga esse ullam provident ad.
                  </div>
               </Col>
               <Col  xxl={10} xl={10} md={12} xs={12} className="content-items-privacy">
               <h2 className='card-title-privacy'>
                    TITLE
                  </h2>
                  <div className='card-description-privacy'>
                    Lorem ipsum dolor sit, amet consectetur adipisicing elit. Dolorum neque porro aut animi iusto, error in doloribus necessitatibus expedita voluptatibus molestias architecto enim quibusdam excepturi fuga esse ullam provident ad.
                  </div>
               </Col>
               <Col  xxl={10} xl={10} md={12} xs={12} className="content-items-privacy">
               <h2 className='card-title-privacy'>
                    TITLE
                  </h2>
                  <div className='card-description-privacy'>
                    Lorem ipsum dolor sit, amet consectetur adipisicing elit. Dolorum neque porro aut animi iusto, error in doloribus necessitatibus expedita voluptatibus molestias architecto enim quibusdam excepturi fuga esse ullam provident ad.
                  </div>
               </Col>
               <Col  xxl={10} xl={10} md={12} xs={12} className="content-items-privacy">
               <h2 className='card-title-privacy'>
                    TITLE
                  </h2>
                  <div className='card-description-privacy'>
                    Lorem ipsum dolor sit, amet consectetur adipisicing elit. Dolorum neque porro aut animi iusto, error in doloribus necessitatibus expedita voluptatibus molestias architecto enim quibusdam excepturi fuga esse ullam provident ad.
                  </div>
               </Col>
                </Row>
              </div>
            </div>
            <Footer />

          </>

      }</>
  )
}
