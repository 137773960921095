import React, { useState } from 'react'
import { Col, Row } from 'react-bootstrap'

export const LayoutsServices = ({ data }) => {
  const [isPressed, setIsPressed] = useState(false);

  const handleMouseDown = () => {
    setIsPressed(true);
  };

  const handleMouseUp = () => {
    setIsPressed(false);
  };

  return (
    <React.Fragment>
      <div className='container component-layout-services ' id='component-layout-services'>
        <Row className=''  xl={12} md={12} sm={12} xs={12} >
          {data.map((data, index) => (

            <Col className='content-card-services' key={index}  xl={4} md={6} sm={12} xs={12} >
              <div     className={`image-containerSrv ${isPressed ? 'pressed' : ''}`}
                id='service-card'
                onMouseDown={handleMouseDown}
                onMouseUp={handleMouseUp}
             >
                <img src={data.multimedia.url} alt="services-img" className="imageSrv" />

                <div className='image-overlay'>

                  <h1 className='title-services' >{data.title ? data.title : 'Title'}</h1>

                  <p className='display-parraf' >{data.description ? data.description : 'description'}</p>
                </div>
              </div>
            </Col>


          ))}


        </Row>
      </div>
    </React.Fragment>
  )
}
