import React, { useEffect, useState } from 'react'
import './cursor.css'
export const CursorCustom = () => {

    const [isClicked, setIsClicked] = useState(false);

    useEffect(() => {
        const cursor = document.querySelector('.cursorCustom');

        const onMouseMove = (e) => {
            cursor.style.transition = 'none';
            let x = e.pageX;
            let y = e.pageY;
            cursor.setAttribute('style', `left: ${x}px; top: ${y}px`);
        };

        const onMouseDown = () => {
            setIsClicked(true);
        };

        const onMouseUp = () => {
            setIsClicked(false);
        };

        window.addEventListener('mousemove', onMouseMove);
        window.addEventListener('mousedown', onMouseDown);
        window.addEventListener('mouseup', onMouseUp);

        return () => {
            window.removeEventListener('mousemove', onMouseMove);
            window.removeEventListener('mousedown', onMouseDown);
            window.removeEventListener('mouseup', onMouseUp);
        };
    }, []);
    return (


            <div className={`cursorCustom ${isClicked ? 'click' : ''}`}> 

          </div>

        // <div className='cursorDefoult'>

        //     <img src={arrow} alt="cursor" className='cursor-arrow' />
        // </div>



    )
}
