import React from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import '../components.css'
import { useMediaQuery } from 'react-responsive'
export const ListClients = ({ data }) => {
    const isScreenLarge = useMediaQuery({ minWidth: 992 });
    return (
        <div className={`${isScreenLarge ? 'container' : 'container-fluid'}  content-component-list-clients`}>

            <Row xs={12} md={12} xl={10} xxl={10} className=' justify-content-start'>
                {data.map((item, index) => (
                    <Col xs={12} md={6} xl={3} xxl={3} key={index} >
                        <Card className='card-client-list'>
                            <div className='content-car-list-client'>
                                <div className='card-title-client' >{item.title}</div>
                                <div className='card-description-client'>
                                    {item.description}
                                </div>

                            </div>
                        </Card>
                    </Col>
                ))}
            </Row>
        </div>
    )
}
