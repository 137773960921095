import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Home } from './pages/Home/Home';
import { About } from './pages/About/About.jsx';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Contact } from './pages/Contact/Contact.jsx';
import './index.css';
import { Services } from './pages/ServicesDelfos/Services.jsx';
import { Briefcase } from './pages/Briefcase/Briefcase.jsx';
import { Clients } from './pages/Client/Clients';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/bundle';
import { PrivacyPolicy } from './pages/PrivacyPolicy';
import { NotFound } from './pages/NotFount404/NotFound';
function App() {


  return (
    <React.Fragment>

      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path='/nosotros' element={<About />} />
        {/* <Route exact path='/contacto' element={<Contact />} /> */}
        <Route exact path='/servicios' element={<Services />} />
        <Route exact path='/portafolio' element={<Briefcase />} />
        <Route exact path='/clientes' element={<Clients />} />
        <Route exact path='/politicas-de-privacidad' element={<PrivacyPolicy />} />
        <Route path='error/*' element={<NotFound />} />


        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />


      </Routes>
      {/* <Footer /> */}
    </React.Fragment>
  );
}

export default App;
