import React from 'react'
import './layouts.css'
export const LayoutsTechnologies = ({data}) => {
  const clonedLogos = [...data, ...data]; // Clona los logos para asegurar transición continua

  return (
    <React.Fragment>

      <div className='text-center container-fluid'>
        <div className="content-tecnologies-min">
          <div className="carousel-frmk">
            <div className="image-container-frmk">
              {clonedLogos.map((logo, index) => (
                <img
                  key={index}
                  src={logo.multimedia.url}
                  alt={`Logo-Framework ${index}`}
                  className="logo-frmk"
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>

  )
}
