export const dataApiWebsiteClients={
    "code": 200,
    "status": "success",
    "message": "Confirmed",
    "data": {
      "type": "page",
      "id": "clients",
      "attributes": {
        "block-banner": {
          "type": "block",
          "id": "banner",
          "attributes": {
            "title": "Nuestros clientes",
            "description":"Ofrecemos a nuestros clientes una experiencia excepcional en el desarrollo de sus proyectos, dando lo mejor en cada etapa hacia el cumplimiento del objetivo.",
            "multimedia": {
              "type": "video",
              "url": "",
              "alt": "",
              "title": "",
              "embed": ""
            }
          }
        },
        "block-list-clients": {
          "type": "block",
          "attributes": {
            "items": [
              {
                "title": "Aval",
                "description": "Consultoría y desarrollo de data de negocios.",
              },
              {
                "title": "BCP",
                "description": "Implementación de aplicaciones power apps",
              },           
              {
                "title": "Cementos Pacasmayo",
                "description": "Desarrollo y diseño de plataformas.",
              },
              {
                "title": "EFC",
                "description": "Consultoría y diseño de procesos y arquitectura.",
              },
              {
                "title": "Expertia",
                "description": "Desarrollo, diseño, consultoría sobre plataformas y entrega de capacidades técnicas.",
              },
              {
                "title": "Fundición Central",
                "description": "Desarrollo de ERP de negocios.",
              },
              {
                "title": "Grupo EFE",
                "description": "Squad ágil para la creación del proyecto “Juntoz afiliados”.",
              },
              {
                "title": "Grupo Fe",
                "description": "Construcción de nuevo core de negocio.",
              },
              {
                "title": "Honda del Perú",
                "description": "Desarrollo, consultoría y diseño de plataformas.",
              },
              {
                "title": "Inteligo",
                "description": "Desarrollo y diseño de plataforma de gestión de personal.",
              },
              {
                "title": "ISM",
                "description": "Consultoría de transformación digital e innovación.",
              },
              {
                "title": "izipay",
                "description": "Desarrollo, diseño, consultoría sobre plataformas y entrega de capacidades técnicas.",
              },
              {
                "title": "Jockey Plaza",
                "description": "Diseño y desarrollo web.",
              },
              {
                "title": "Luxury Club",
                "description": "Desarrollo, consultoría y diseño de core de negocio.",
              },
              {
                "title": "NMViajes",
                "description": "Consultoría y rediseño de plataforma web.",
              },
              {
                "title": "Prosegur",
                "description": "Implementación de power apps.",
              },
              {
                "title": "PrudentialSAF",
                "description": "Diseño e implementación del aplicación web y mobile.",
              },
              {
                "title": "RPP",
                "description": "Desarrollo y diseño de plataformas.",
              },
              {
                "title": "Saga Falabella",
                "description": "Implementación de RPA.",
              },
              {
                "title": "San Fernando",
                "description": "Desarrollo y diseño de plataformas.",
              }
            ]
          }
        },
      },    
    },
    "included": [
      {
        "type": "block",
        "id": "menu",
        "attributes": {
          "items": [
            {
              "text": "Contáctenos",
              "url": ""
            },
            {
              "text": "Servicios",
              "url": ""
            },
            {
              "text": "Clientes",
              "url": ""
            },
            {
              "text": "Portafolio",
              "url": ""
            },     {
              "text": "Conócenos",
              "url": ""
            },
            {
              "text": "Home",
              "url": "",
              "multimedia": {
                "type": "image",
                "url": "",
                "alt": "",
                "title": "",
                "embed": ""
              },
            }
          ]
        }
      },
      {
        "type": "block",
        "id": "menu-mobile",
        "attributes": {
          "items": [
            {
              "text": "Contáctenos",
              "url": ""
            },
            {
              "text": "Servicios",
              "url": ""
            },
            {
              "text": "Portafolio",
              "url": ""
            }, {
              "text": "Conócenos",
              "url": ""
            },
            {
              "text": "Home",
              "url": "",
              "multimedia": {
                "type": "image",
                "url": "",
                "alt": "",
                "title": "",
                "embed": ""
              },
            }
          ],
          "footer-menu": {
            "title": "Escríbenos",
            "email": "contacto@delfosti.com",
            "link": {
              "text": "Siguenos en ",
              "url": "https://www.linkedin.com/company/delfosti/mycompany/"
            },
            "address": "Calle José Gálvez 692, Miraflores",
          }
        }
      },
      {
        "type": "block",
        "id": "informative",
        "attributes": {
          "title": "Trabajemos juntos",
          "description": "Si estás buscando un alidado para hacer realidad tu proyecto, estamos para ayudarte.",
          "link": {
            "text": "Quiero saber más",
            "url": ""
          }
        }
      },
      {
        "type": "block",
        "id": "linkedin",
        "attributes": {
          "title": "Siguenos en LinkedIn",
          "description": "Conoce las novedades y oportunidades laborales ",
          "link": {
            "multimedia": {
              "type": "image",
              "url": "",
              "alt": "",
              "title": "",
              "embed": ""
            },
            "url": ""
          },
        }
      },
      {
        "type": "block",
        "id": "footer",
        "attributes": {
          "title": "Comunícate con nosotros",
          "description": "Escríbenos para comenzar el proyecto que tienes en mente.",
          "email": "contacto@delfosti.com",
          "link": {
            "text": "Siguenos en",
            "url": "https://www.linkedin.com/company/delfosti/mycompany/"
          }, 
          "multimedia": {
            "type": "image",
            "url": "",
            "alt": "",
            "title": "",
            "embed": "",
            'link':""
          },
          "copyright":"© 2023 Delfosti. Todos los derechos reservados."
        }
      }
    ],
    "meta": {
      "seo_title": "Delfosti",
      "seo_description": "Transformamos tu empresa a través de la innovación para ofrecerte soluciones increíbles.",
      "seo_keywords": "Delfosti, Desarrollo de Software, Soluciones Tecnológicas, Innovación Digital, Transformación Digital, Desarrollo de Aplicaciones, Soluciones Personalizadas, Ingeniería de Software, Consultoría Tecnológica, Programación Avanzada, Desarrollo Ágil, Soluciones Empresariales, Arquitectura de Software, Desarrollo a Medida, Experiencia de Usuario (UX), Soluciones Cloud, Integración de Sistemas, Consultoría en TI, Automatización de Procesos, Tecnologías Emergentes, Estrategias Digitales.",
      "seo_url": "https://delfosti.com"
    },
    "links": {}
  }