import React, { useEffect, useRef, useState } from 'react'
import arrow from '../../assets/logo/arrowCursor.png'
import './cursor.css'
export const CursorDefault = () => {
    const [isClicked, setIsClicked] = useState(false);
    const cursorRef = useRef(null);
  
    useEffect(() => {
      const onMouseMove = (e) => {
        if (cursorRef.current) {
          cursorRef.current.style.transition = 'none';
          let x = e.pageX;
          let y = e.pageY;
          cursorRef.current.setAttribute('style', `left: ${x}px; top: ${y}px`);
        }
      };
  
      const onMouseDown = () => {
        setIsClicked(true);
      };
  
      const onMouseUp = () => {
        setIsClicked(false);
      };
  
      if (cursorRef.current) {
        cursorRef.current.addEventListener('click', () => {
          // Handle click events here
          // You can add custom logic when the cursor is clicked on a component
          console.log('Cursor clicked!');
        });
      }
  
      window.addEventListener('mousemove', onMouseMove);
      window.addEventListener('mousedown', onMouseDown);
      window.addEventListener('mouseup', onMouseUp);
  
      return () => {
        window.removeEventListener('mousemove', onMouseMove);
        window.removeEventListener('mousedown', onMouseDown);
        window.removeEventListener('mouseup', onMouseUp);
      };
    }, []);
  
    // Verificar si el elemento actual tiene la clase 'show-cursor'
    const isCursorVisible = cursorRef.current?.classList.contains('show-cursor');
  
    return (



        <div className={`cursorDefoult ${isCursorVisible ? 'visible' : ''}`} ref={cursorRef}>

            <img src={arrow} alt="cursor" className='cursor-arrow' />
        </div>



    )
}
