import React from 'react';
import imgResponsive from '../../assets/img/circleresponsive.png'
import imgC from '../../assets/img/components/circleF.svg.svg'
import { WorkingDelfos } from './WorkingDelfos';
import { useMediaQuery } from 'react-responsive';

const ImageContainer = ({data}) => {
  const isScreenLarge = useMediaQuery({ minWidth: 770 });

  return (

<div className="content-block-working-delfos">
<div className=" container-fluid component-content image-container" id='content-block-working-delfos'>
   
   <div className="row row-cols-xxl-12 moving-image">
      <img src={isScreenLarge ? imgC : imgResponsive} alt="delfosti-hover" className={isScreenLarge ? 'content-move' : 'content-move-responsive'} />
    </div>
    <div className="overlay">
      <WorkingDelfos 
      data={data}
      />
    </div>
   </div>
</div>



  );
};

export default ImageContainer;
