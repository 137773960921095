import {
  imgBriefcase1,
  imgBriefcase2,
  imgBriefcase3,
  imgBriefcase4,
  imgBriefcase5,
  imgBriefcase6,
  imgBriefcase7,
  imgBriefcase8,
  imgBriefcase9,
  imgBriefcase10,
  imgBriefcase11,
  imgBriefcase12,
  imgBriefcase13,
  imgBriefcase14,
  imgBriefcase15,
  imgBriefcase16,
  imgBriefcase17,
  imgBriefcase18,
  imgBriefcase19,
  imgBriefcase20,
} from "./image/imgBriefcase.ts";
export const dataApiWebsiteBriefcase = {
  "code": 200,
  "status": "success",
  "message": "Confirmed",
  "data": {
    "type": "page",
    "id": "briefcase",
    "attributes": {
      "block-banner": {
        "type": "block",
        "id": "banner",
        "attributes": {
          "title": "Transforma tu empresa",
          "description": "Co-creamos en equipo para hacer de la tecnología una herramienta para los negocios, generando nuevas ideas y convirtiéndolas en realidad",
          "multimedia": {
            "type": "video",
            "url": "",
            "alt": "",
            "title": "",
            "embed": ""
          }
        }
      },
    },
    "relationships": {
      "block-gallery": {
        "type": "block",
        "id": "gallery",
        "attributes": {
          "select": [{
            "title": "ver todos", 
            "id": ""
          },
          {
            "title": "aplicativos mobiles", 
            "id": "app"
          },
          {
            "title": "paginas web", 
            "id": "website"
          },
          {
            "title": "sistemas internos", 
            "id": "system"
          },
          {
            "title": "Integraciones", 
            "id": "integration"
          },
          {
            "title": "", 
            "id": ""
          },

          ],
          "items": [
            {
              "name": "",
              "type": "website",
              "multimedia": {
                "type": "image",
                "url": imgBriefcase1,
                "alt": "",
                "title": "",
                "embed": ""
              },
              "url": ""
            },
            {
              "name": "",
              "type": "app",
              "multimedia": {
                "type": "image",
                "url": imgBriefcase2,
                "alt": "",
                "title": "",
                "embed": ""
              },
              "url": ""
            },
            {
              "name": "",
              "type": "services",
              "multimedia": {
                "type": "image",
                "url": imgBriefcase3,
                "alt": "",
                "title": "",
                "embed": ""
              },
              "url": ""
            },
            {
              "name": "",
              "type": "website",
              "multimedia": {
                "type": "image",
                "url": imgBriefcase4,
                "alt": "",
                "title": "",
                "embed": ""
              },
              "url": ""
            },
            {
              "name": "",
              "type": "app",
              "multimedia": {
                "type": "image",
                "url": imgBriefcase5,
                "alt": "",
                "title": "",
                "embed": ""
              },
              "url": ""
            },
            {
              "name": "",
              "type": "services",
              "multimedia": {
                "type": "image",
                "url": imgBriefcase6,
                "alt": "",
                "title": "",
                "embed": ""
              },
              "url": ""
            },
            {
              "name": "",
              "type": "services",
              "multimedia": {
                "type": "image",
                "url": imgBriefcase6,
                "alt": "",
                "title": "",
                "embed": ""
              },
              "url": ""
            },
            {
              "name": "",
              "type": "services",
              "multimedia": {
                "type": "image",
                "url": imgBriefcase7,
                "alt": "",
                "title": "",
                "embed": ""
              },
              "url": ""
            },
            {
              "name": "",
              "type": "services",
              "multimedia": {
                "type": "image",
                "url": imgBriefcase8,
                "alt": "",
                "title": "",
                "embed": ""
              },
              "url": ""
            },
            {
              "name": "",
              "type": "services",
              "multimedia": {
                "type": "image",
                "url": imgBriefcase8,
                "alt": "",
                "title": "",
                "embed": ""
              },
              "url": ""
            },
            {
              "name": "",
              "type": "services",
              "multimedia": {
                "type": "image",
                "url": imgBriefcase9,
                "alt": "",
                "title": "",
                "embed": ""
              },
              "url": ""
            },
            {
              "name": "",
              "type": "services",
              "multimedia": {
                "type": "image",
                "url": imgBriefcase10,
                "alt": "",
                "title": "",
                "embed": ""
              },
              "url": ""
            },
            {
              "name": "",
              "type": "services",
              "multimedia": {
                "type": "image",
                "url": imgBriefcase11,
                "alt": "",
                "title": "",
                "embed": ""
              },
              "url": ""
            },
            {
              "name": "",
              "type": "services",
              "multimedia": {
                "type": "image",
                "url": imgBriefcase12,
                "alt": "",
                "title": "",
                "embed": ""
              },
              "url": ""
            },
            {
              "name": "",
              "type": "services",
              "multimedia": {
                "type": "image",
                "url": imgBriefcase13,
                "alt": "",
                "title": "",
                "embed": ""
              },
              "url": ""
            },
            {
              "name": "",
              "type": "services",
              "multimedia": {
                "type": "image",
                "url": imgBriefcase14,
                "alt": "",
                "title": "",
                "embed": ""
              },
              "url": ""
            },
            {
              "name": "",
              "type": "services",
              "multimedia": {
                "type": "image",
                "url": imgBriefcase15,
                "alt": "",
                "title": "",
                "embed": ""
              },
              "url": ""
            },
            {
              "name": "",
              "type": "services",
              "multimedia": {
                "type": "image",
                "url": imgBriefcase16,
                "alt": "",
                "title": "",
                "embed": ""
              },
              "url": ""
            },
            {
              "name": "",
              "type": "services",
              "multimedia": {
                "type": "image",
                "url": imgBriefcase17,
                "alt": "",
                "title": "",
                "embed": ""
              },
              "url": ""
            },
            {
              "name": "",
              "type": "services",
              "multimedia": {
                "type": "image",
                "url": imgBriefcase18,
                "alt": "",
                "title": "",
                "embed": ""
              },
              "url": ""
            },
            {
              "name": "",
              "type": "services",
              "multimedia": {
                "type": "image",
                "url": imgBriefcase19,
                "alt": "",
                "title": "",
                "embed": ""
              },
              "url": ""
            },
            {
              "name": "",
              "type": "services",
              "multimedia": {
                "type": "image",
                "url": imgBriefcase20,
                "alt": "",
                "title": "",
                "embed": ""
              },
              "url": ""
            },
          ],

        }
      },
    }
  },
  "included": [
    {
      "type": "block",
      "id": "menu",
      "attributes": {
        "items": [
          {
            "text": "Contáctenos",
            "url": ""
          },
          {
            "text": "Servicios",
            "url": ""
          },
          {
            "text": "Clientes",
            "url": "",
          },
          {
            "text": "Portafolio",
            "url": ""
          },
          {
            "text": "Conócenos",
            "url": ""
          },
          {
            "text": "Home",
            "url": "",
            "multimedia": {
              "type": "image",
              "url": "",
              "alt": "",
              "title": "",
              "embed": ""
            }
          }
        ]
      }
    },
    {
      "type": "block",
      "id": "menu-mobile",
      "attributes": {
        "items": [
          {
            "text": "Contáctenos",
            "url": ""
          },
          {
            "text": "Servicios",
            "url": ""
          },
          {
            "text": "Portafolio",
            "url": ""
          }, {
            "text": "Conócenos",
            "url": ""
          },
          {
            "text": "Home",
            "url": "",
            "multimedia": {
              "type": "image",
              "url": "",
              "alt": "",
              "title": "",
              "embed": ""
            },
          }
        ],
        "footer-menu": {
          "title": "Escríbenos",
          "email": "contacto@delfosti.com",
          "link": {
            "text": "Siguenos en",
            "url": "https://www.linkedin.com/company/delfosti/mycompany/"
          },
          "address": "Calle José Gálvez 692, Miraflores",
        }
      }
    },
    {
      "type": "block",
      "id": "informative",
      "attributes": {
        "title": "Trabajemos juntos",
        "description": "Si estás buscando un alidado para hacer realidad tu proyecto, estamos para ayudarte.",
        "link": {
          "text": "Quiero saber más",
          "url": ""
        }
      }
    }, 
    {
      "type": "block",
      "id": "linkedin",
      "attributes": {
        "title": "Siguenos en LinkedIn",
        "description": "Conoce las novedades y oportunidades laborales ",
        "link": {

          "multimedia": {
            "type": "image",
            "url": "",
            "alt": "",
            "title": "",
            "embed": ""
          },
          "url": ""
        },
      }
    },
    {
      "type": "block",
      "id": "footer",
      "attributes": {
        "title": "Comunícate con nosotros",
        "description": "Escríbenos para comenzar el proyecto que tienes en mente.",
        "email": "contacto@delfosti.com",
        "link": {
          "text": "Siguenos en ",
          "url": "https://www.linkedin.com/company/delfosti/mycompany/"
        }, 
        "multimedia": {
          "type": "image",
          "url": "",
          "alt": "",
          "title": "",
          "embed": "",
          'link':""
        },
        "copyright":"© 2023 Delfosti. Todos los derechos reservados."
      }
    }
  ],
  "meta": {
    "seo_title": "Delfosti",
    "seo_description": "Transformamos tu empresa a través de la innovación para ofrecerte soluciones increíbles.",
    "seo_keywords": "Delfosti, Desarrollo de Software, Soluciones Tecnológicas, Innovación Digital, Transformación Digital, Desarrollo de Aplicaciones, Soluciones Personalizadas, Ingeniería de Software, Consultoría Tecnológica, Programación Avanzada, Desarrollo Ágil, Soluciones Empresariales, Arquitectura de Software, Desarrollo a Medida, Experiencia de Usuario (UX), Soluciones Cloud, Integración de Sistemas, Consultoría en TI, Automatización de Procesos, Tecnologías Emergentes, Estrategias Digitales.",
    "seo_url": "https://delfosti.com"
  },
  "links": {}
}