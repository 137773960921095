import React, { useEffect, useState } from "react";
import { Header } from "../../components/Header";
import { BlockCircle } from "./BlockCircle";
import { BlockObjetives } from "./BlockObjetives";
import { LoadPage } from "../../components/LoadPage";
import { Footer } from "../../components/Footer";
import ImageContainer from "../../components/WorkingDelfos/ImageContainer";
import { Linkelnd } from "../../components/Socials/Linkelnd";
import { ClientsLogo } from "../../components/Clients/ClientsLogo";
import { BlockChoose } from "../../components/BlockChoose";
import Spline from "@splinetool/react-spline";
import { Button, Col, Row, Spinner } from "react-bootstrap";
import { ImageLayout } from "../../components/Projects/ImageLayout";
import { Title } from "../../components/Title";
import { dataApiWebsiteHome } from "../../utils/DataApiWebsiteHome";
import { CursorCustom } from "../../components/Cursor/CursorCustom";
import { CursorDefault } from "../../components/Cursor/CursorDefault";
import { Link } from "react-router-dom";
import { VideoComponentHome } from "../../components/VideoComponentHome";
export const Home = () => {
  const [text, setText] = useState("");
  const [LoadCanva, setLoadCanva] = useState(true);
  const [isFirstLoad, setIsfirstLoad] = useState(true);
  const dataWebsite = dataApiWebsiteHome;
  const fullText =
    dataWebsite.data.attributes["block-main"].attributes["title"] +
    " " +
    dataWebsite.data.attributes["block-main"].attributes["subtitle"];
  const blockBanner = dataWebsite.data.attributes["block-banner"].attributes;
  // const blockMain = dataWebsite.data.attributes['block-main'].attributes
  const blockMainItems =
    dataWebsite.data.attributes["block-main"].attributes.items;
  const blockChoose = dataWebsite.data.attributes["block-choose"].attributes;
  const blockObjetives =
    dataWebsite.data.attributes["block-objetives"].attributes;
const custumerData = dataWebsite.data.relationships["block-customers"].attributes.items
  const [showCustomCursor, setShowCustomCursor] = useState(false);
const video = dataApiWebsiteHome.data.attributes["block-banner"].attributes.multimedia.url
  // Function to toggle the custom cursor
  const toggleCustomCursor = (show) => {
    setShowCustomCursor(show);
    console.log(show);
  };

  const linkedinBlock = dataWebsite.included.find(
    (block) => block.id === "linkedin"
  );
  const FooterBlock = dataWebsite.included.find(
    (block) => block.id === "footer"
  );
  const blockInformative = dataWebsite.included.find(
    (block) => block.id === "informative"
  );
  useEffect(() => {
    setInterval(() => {
      setIsfirstLoad(false);
    }, 5000);
    setInterval(() => {
      setLoadCanva(false);
    }, 5500);
  });

  useEffect(() => {
    let currentIndex = 0;
    const interval = setInterval(() => {
      if (currentIndex < fullText.length) {
        setText(fullText.slice(0, currentIndex + 1));
        currentIndex++;
      } else {
        clearInterval(interval);
      }
    }, 100);

    return () => clearInterval(interval);
  }, [fullText]);

  return (
    <>
      {/* Include custom cursor */}
      {showCustomCursor && <CursorCustom />}
      {/* Include default cursor */}
      {!showCustomCursor && <CursorDefault />}

      {/* <LoadPage textTitle={'delodsnvljenflke'} /> */}
      {isFirstLoad ? (
        <LoadPage textTitle={blockBanner.title} />
      ) : (
        <React.Fragment>
            <div className='content-block-header-blue'>
            <div className="content-components-header-home ">
              <Header />
              <div className="container-fluid">
                <h1 className="title-header  row-cols-xl-12 row-cols-md-12 row-cols-12 ">
                  {blockBanner.title}
                </h1>
              </div>
            </div>
            {LoadCanva ? (
              <div className="text-center content-load-spninner-home">
                <Spinner animation="grow" variant="light" />
                <Spinner animation="grow" variant="light" />
                <Spinner animation="grow" variant="light" />
              </div>
            ) : (
              <>

                <VideoComponentHome video={video}/>
              </>
            )}
          </div>

          <div
            className="content-block-circle data-first"
            id="content-block-circle"
          >
            <div className="content-title-primary">
              <div className="container">
                <Row xl={10} md={12} xs={12}>
                  <Col
                    xl={12}
                    md={12}
                    xs={12}
                    className="content-title-primary"
                  >
                    <h1 className="title-primary text-center">
                      {text.slice(0, 24)}
                    </h1>
                    <span className="subtitle-primary text-center">
                      {text.slice(25)}
                    </span>
                  </Col>
                </Row>
              </div>
            </div>
            <div className="content-circle" id="content-circle">
              <BlockCircle data={blockMainItems} />
            </div>
          </div>

          <div
            className={`content-block-gallery-briefcase }`}
            id="content-block-gallery-briefcase"
          >
            <div
              className={` container-component-gallery text-center`}
              id="component-gallery-projects"
            >
              {" "}
              <ImageLayout
                data={dataWebsite}
                className={` ${
                  showCustomCursor ? "custom-cursor" : "default-cursor"
                }`}
                onMouseEnter={() => toggleCustomCursor(true)}
                onMouseLeave={() => toggleCustomCursor(false)}
              />
              <div className="btn-layouts-services">
                <Link to={"/portafolio"} className="link-projects">
                  <Button className="btn-projects">Ver más proyectos</Button>
                </Link>
              </div>
            </div>
          </div>

          <div className="content-block-objetives" id="content-block-objetives">
            <BlockObjetives
              title={blockObjetives.title}
              description={blockObjetives.description}
              data={blockObjetives.items}
            />
          </div>

          <div className="content-block-choose" id="content-block-choose">
            <div
              className="content-component-block-chose"
              id="content-component-choose"
            >
              <Title
                className=""
                title={blockChoose.title}
                description={blockChoose.description}
              />
              <BlockChoose data={blockChoose.items} />
            </div>
          </div>

          <div
            className="content-block-client-logo"
            id="content-block-client-logo"
          >
            <div className="content-component-logo-client">
              <ClientsLogo data={custumerData} />
            </div>
          </div>

          <div
            className="content-block-work-delfosti"
            id="content-block-work-delfosti"
          >
            <ImageContainer data={blockInformative.attributes} />
          </div>

          <div
            className="content-block-component-linkld"
            id="content-block-component-linkld"
          >
            <Linkelnd
              title={linkedinBlock.attributes.title}
              url={linkedinBlock.attributes.url}
              description={linkedinBlock.attributes.description}
            />
          </div>

          <Footer
            title={FooterBlock.attributes.title}
            description={FooterBlock.attributes.description}
            email={FooterBlock.attributes.email}
            linktext={FooterBlock.attributes.link.text}
            linkUrl={FooterBlock.attributes.link.linkUrl}
            copyright={FooterBlock.attributes.copyright}
          />
        </React.Fragment>
      )}
    </>
  );
};
