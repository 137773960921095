import React from 'react'
import video from '../assets/video/videoHome/Delfosweb3DHome.mp4'
import videomin from '../assets/video/800x1200.mp4'
import logoEmail from '../assets/img/components/icon_email.svg'
import logoPhone from '../assets/img/components/phone.svg'
import './components.css'
import { Row } from 'react-bootstrap'
export const VideoComponentHome = ({video}) => {
    return (
        <div className='component-video-header pb-10 '>
          <video autoPlay loop muted id='video' className='video-lopp video-max-width overflow-visible'>
            <source src={video} type='video/mp4' className='video-max-width' />
          </video>
          <video autoPlay loop muted id='video' className='video-lopp video-min-width'>
            <source src={video} type='video/mp4' className='video-min-width' />
          </video>
        </div>
      )
}
