import React, { useEffect, useState } from 'react'
import { Button, Col, NavLink, Navbar } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import logoSvg from '../assets/logo/Logo_Delfosti_primary.svg'
import logoSvgWhite from '../assets/logo/logoWhite.svg'
import logoDWhite from '../assets/logo/logo-white.png'
import logoDBlue from '../assets/logo/logo-blue.png'
import arrow from '../assets/img/components/Style=Filled.svg'
import arrowW from '../assets/img/components/arrowwhite.svg'
import { useLocation } from 'react-router-dom';
import menu from '../assets/menu.png'
import menuWhite from '../assets/menu-white.png'
import { NavbarResponsive } from './NavbarResponsive'
import { useMediaQuery } from 'react-responsive'
export const Header = () => {

    const [isHovered, setIsHovered] = useState(false);
    const [smallScreen, setSmallScreen] = useState(false); // Track if the screen is small

    const [headerStyle, setHeaderStyle] = useState({
        colorNav: 'white',
        hoverAnimation: 'hover-animation-white'
    });// Default color is white
    const location = useLocation();

    const getHeaderStyle = (pathname) => {
        return pathname === '/home' ? { hoverAnimation: 'hover-animation-blue', colorNav: 'blue' } : { hoverAnimation: 'hover-animation-white', colorNav: 'white' };
    };

    const isScreenLarge = useMediaQuery({ minWidth: 992 });
    useEffect(() => {
        const style = getHeaderStyle(location.pathname);
        setHeaderStyle(style);

        // Check if the screen is small initially
        if (window.innerWidth < 990) {
            setSmallScreen(true);
        }

        // Listen for window resize event
        const handleResize = () => {
            if (window.innerWidth < 990) {
                setSmallScreen(true);
            } else {
                setSmallScreen(false);
            }
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [location.pathname]);



    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    return (
        <React.Fragment>
            {/* //<!-- Navbar --> */}
            <nav className={`container-fluid  bg-body-${headerStyle.colorNav} navbar navbar-expand-lg `}  >
                {/* //<!-- Container wrapper --> */}
                <Col xl={1} md={1} xs={2} >
                    <NavLink className={`${isScreenLarge ? 'content-logo-header': 'content-logo-header-responsive'} text-start border-0`} eventKey={1} as={Link} to="/"> <img
                        src={
                            smallScreen
                                ? headerStyle.colorNav === 'blue'
                                    ? logoDBlue
                                    : logoDWhite
                                : headerStyle.colorNav === 'blue'
                                    ? logoSvg
                                    : logoSvgWhite
                        } alt="logo_delfosti" /></NavLink>
                </Col>
                {/* ----------------------comentado Boton conocenos----------------------- */}
                {/*  <Col xl={8} md={8} xs={8} className='navbar-toggler text-end border-0' >
                    <Link to={'/contacto'} >
                        <Button className='content-button-header'
                            variant={headerStyle.colorNav === 'blue' ? 'outline-primary border-2' : 'outline-light border-2'}
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}>
                            {
                                headerStyle.colorNav === 'white' ? (

                                    isHovered ?
                                        <>
                                            <span className='m-1 button-hover-white '>Conversemos</span>
                                            <img src={arrow} className="image mb-1" alt="arrow" />
                                        </>
                                        :
                                        <>
                                            <span className='m-1 button-hover-none-white'>Conversemos</span>
                                            <img src={arrowW} className="image mb-1" alt="arrow" />
                                        </>
                                ) : (
                                    isHovered ?
                                        <>
                                            <span className='m-1 button-hover-none-blue'>Conversemos</span>
                                            <img src={arrowW} className="image mb-1" alt="arrow" />
                                        </> :
                                        <>
                                            <span className='m-1 button-hover-none-blue'>Conversemos</span>
                                            <img src={arrow} className="image mb-1" alt="arrow" />
                                        </>
                                )
                            }
                        </Button>
                    </Link>
                </Col> */}
                <Col xl={8} md={6} className='collapse navbar-collapse text-start' >
                    <NavLink className={`hover-underline-animation ${headerStyle.hoverAnimation} ${headerStyle.colorNav === 'blue' ? 'nav-bar-blue-animation' : 'nav-bar-white-animation'}  text-start`} eventKey={2} as={Link} to="/nosotros">Conócenos</NavLink>
                    <NavLink className={`hover-underline-animation ${headerStyle.hoverAnimation} ${headerStyle.colorNav === 'blue' ? 'nav-bar-blue-animation' : 'nav-bar-white-animation'} text-start`} eventKey={3} as={Link} to="/servicios" >Servicios</NavLink>
                    <NavLink className={` hover-underline-animation ${headerStyle.hoverAnimation}  ${headerStyle.colorNav === 'blue' ? 'nav-bar-blue-animation' : 'nav-bar-white-animation'} text-start`} eventKey={4} as={Link} to="/clientes" >Clientes</NavLink>
                    <NavLink className={` hover-underline-animation ${headerStyle.hoverAnimation}  ${headerStyle.colorNav === 'blue' ? 'nav-bar-blue-animation' : 'nav-bar-white-animation'} text-start`} eventKey={5} as={Link} to="/portafolio" >Portafolio</NavLink>
                </Col>
                {/* ----------------comentado Boton conocenos--------------------- */}
                <Col xl={2} md={3} xs={2} className='text-end content-navbar-end'>
                    <Navbar.Text className='collapse navbar-collapse justify-content-end navbar-collapse-true-hidden'>
                        {/* <Link to={'/contacto'} >
                            <Button className='content-button-header'
                                variant={headerStyle.colorNav === 'blue' ? 'outline-primary border-2' : 'outline-light border-2'}
                                onMouseEnter={handleMouseEnter}
                                onMouseLeave={handleMouseLeave}>

                                {
                                    headerStyle.colorNav === 'white' ? (

                                        isHovered ?
                                            <>
                                                <span className='m-1 button-hover-white '>Conversemos</span>
                                                <img src={arrow} className="image mb-1" alt="arrow" />
                                            </>
                                            :
                                            <>
                                                <span className='m-1 button-hover-none-white'>Conversemos</span>
                                                <img src={arrowW} className="image mb-1" alt="arrow" />
                                            </>
                                    ) : (
                                        isHovered ?
                                            <>
                                                <span className='m-1 button-hover-none-blue'>Conversemos</span>
                                                <img src={arrowW} className="image mb-1" alt="arrow" />
                                            </> :
                                            <>
                                                <span className='m-1 button-hover-none-blue'>Conversemos</span>
                                                <img src={arrow} className="image mb-1" alt="arrow" />
                                            </>
                                    )
                                }


                            </Button>

                        </Link> */}
                    </Navbar.Text>
                    <button
                        className="navbar-toggler border-0 text-end"
                        type="button"
                        data-mdb-toggle="collapse"
                        data-mdb-target="#navbar-bullets"
                        aria-controls="navbar-bullets"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <img src={headerStyle.colorNav === 'blue' ? menu : menuWhite} alt="" />
                    </button>
                    <div className='show-navbar' id='navbar-bullets'>
                        <NavbarResponsive />
                    </div>
                </Col>
            </nav>
        </React.Fragment>
    )
}
