import React, { useEffect, useState } from 'react'
import { Header } from '../../components/Header'
import { VideoComponent } from '../../components/VideoComponent'
import { LoadPage } from '../../components/LoadPage'
import { Footer } from '../../components/Footer'
import { LayoutsBriefcase } from '../../components/LayoutsImagens/LayoutsBriefcase'
import ImageContainer from '../../components/WorkingDelfos/ImageContainer'
import { Linkelnd } from '../../components/Socials/Linkelnd'
import { Spinner } from 'react-bootstrap'
import { dataApiWebsiteBriefcase } from '../../utils/DataApiWebsiteBriefcase'
import { CursorDefault } from '../../components/Cursor/CursorDefault'
import { CursorCustom } from '../../components/Cursor/CursorCustom'
import { dataImg } from '../../utils/dataPortafolio'

export const Briefcase = ({data}) => {
  const [LoadCanva, setLoadCanva] = useState(true)
  const [isFirstLoad, setIsfirstLoad] = useState(true)
  const [showCustomCursor, setShowCustomCursor] = useState(false);
  const toggleCustomCursor = (show) => {
    setShowCustomCursor(show);
  };

  const dataIm = dataImg;
  const images = [
    { url: dataIm[0].src, type: 'app' },
    { url: dataIm[1].src, type: 'website' },
    { url: dataIm[3].src, type: 'Integración' }, { url: dataIm[4].src, type: 'app' },
    { url: dataIm[5].src, type: 'website' },
    { url: dataIm[2].src, type: 'Integración' }, { url: dataIm[0].src, type: 'app' },
    { url: dataIm[1].src, type: 'website' },
    { url: dataIm[2].src, type: 'Integración' }, { url: dataIm[0].src, type: 'app' },
    { url: dataIm[1].src, type: 'website' },
    { url: dataIm[2].src, type: 'Integración' },
    { url: dataIm[1].src, type: 'services' },
    { url: dataIm[2].src, type: 'Soporte' },
    // Agregar más imágenes aquí
  ];


  const [selectedTab, setSelectedTab] = useState('Ver todos');

  const handleTabClick = (tab) => {
    setSelectedTab(tab);
  };

  const filteredImages = selectedTab === 'Ver todos' ? images : images.filter(image => image.type === selectedTab);

  const dataWebsite = dataApiWebsiteBriefcase;
  const blockBanner = dataWebsite.data.attributes['block-banner'].attributes
  const linkedinBlock = dataWebsite.included.find(
    (block) => block.id === 'linkedin'
  );
  const dataBriefcase = dataApiWebsiteBriefcase.data.relationships['block-gallery'].attributes.items
  const FooterBlock = dataWebsite.included.find(
    (block) => block.id === 'footer'
  );
  const blockInformative = dataWebsite.included.find(
    (block) => block.id === 'informative'
  );
  useEffect(() => {
    setInterval(() => {
      setIsfirstLoad(false)
    }, 5000);
    setInterval(() => {

      setLoadCanva(false)
    }, 5500);
    // console.log(isFirstLoad)


  },)

  return (
    <React.Fragment>
      {showCustomCursor && <CursorCustom />}
      {/* Include default cursor */}
      {!showCustomCursor && <CursorDefault />}
      {
        isFirstLoad ? <LoadPage textTitle={blockBanner.title} /> :
          <>
            <div className='content-block-header-blue-briefcase' id='content-block-header-blue-briefcase'>
              <div className="content-components-headers">
                <Header />
                <div className={`content-transition ${LoadCanva ? 'loading' : ''}`}>
                  {LoadCanva ? (
                    <div className='text-center content-load-spninner'>
                      <Spinner animation='grow' variant='light' />
                      <Spinner animation='grow' variant='light' />
                      <Spinner animation='grow' variant='light' />
                    </div>
                  ) :
                    <>
                      <VideoComponent
                        title={blockBanner.title}
                        subtitle={blockBanner.description} />
                    </>}
                </div>
              </div>
            </div>
            <div className={`content-block-component-galerry`}>
              <div className={`container content-nav-briefcase  `} >
{/*-----FIILTRO COMENTADO */}
              {/*   <div className="tabs tabs-briefcase">
                  <div
                    className={`tab ${selectedTab === 'Ver todos' ? 'selected' : ''}`}
                    onClick={() => handleTabClick('Ver todos')}
                  >
                    Ver todos
                  </div>
                  <div
                    className={`tab ${selectedTab === 'website' ? 'selected' : ''}`}
                    onClick={() => handleTabClick('website')}
                  >
                    Proyectos web
                  </div>
                  <div
                    className={`tab ${selectedTab === 'app' ? 'selected' : ''}`}
                    onClick={() => handleTabClick('app')}
                  >
                    Aplicativos móviles
                  </div>
                  <div
                    className={`tab ${selectedTab === 'services' ? 'selected' : ''}`}
                    onClick={() => handleTabClick('services')}
                  >
                    Sistemas internos
                  </div>

                  <div
                    className={`tab ${selectedTab === 'Integración' ? 'selected' : ''}`}
                    onClick={() => handleTabClick('Integración')}
                  >
                    Imagen Integración
                  </div>
                  <div
                    className={`tab ${selectedTab === 'Soporte' ? 'selected' : ''}`}
                    onClick={() => handleTabClick('Soporte')}
                  >
                    Soporte
                  </div>

                </div> */}
                <LayoutsBriefcase data={dataBriefcase} className={` ${showCustomCursor ? 'custom-cursor' : 'default-cursor'}`} onMouseEnter={() => toggleCustomCursor(true)}
                  onMouseLeave={() => toggleCustomCursor(false)} />
              </div>

            </div>


            <div className={`content-block-component-working-delfos`}>
              <ImageContainer
                data={blockInformative.attributes}
              />
            </div>
            <div className='content-block-component-linkld' id='content-block-component-linkld'>
              <Linkelnd
                title={linkedinBlock.attributes.title}
                url={linkedinBlock.attributes.url}
                description={linkedinBlock.attributes.description}
              />
            </div>
            <Footer
              title={FooterBlock.attributes.title}
              description={FooterBlock.attributes.description}
              email={FooterBlock.attributes.email}
              linktext={FooterBlock.attributes.link.text}
              linkUrl={FooterBlock.attributes.link.linkUrl}
              copyright={FooterBlock.attributes.copyright}
            />
          </>

      }
    </React.Fragment>
  )
}
