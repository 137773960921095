import React, { useEffect, useState } from 'react'
import { Header } from '../../components/Header'
import { VideoComponent } from '../../components/VideoComponent'
import { LoadPage } from '../../components/LoadPage'
import { Footer } from '../../components/Footer'
import ImageContainer from '../../components/WorkingDelfos/ImageContainer'
import { LayoutsTechnologies } from '../../components/LayoutsImagens/LayoutsTechnologies'
import { Title } from '../../components/Title'
import { LayoutsServices } from '../../components/LayoutsImagens/LayoutsServices'
import { OurFramework } from '../../components/OurFramework/OurFramework'
import { Spinner } from 'react-bootstrap'
import { dataApiWebsiteServices } from '../../utils/DataApiWebsiteServices'
import { CursorDefault } from '../../components/Cursor/CursorDefault'
export const Services = () => {
  const [LoadCanva, setLoadCanva] = useState(true)
  const [isFirstLoad, setIsfirstLoad] = useState(true)
  const dataWebsite = dataApiWebsiteServices;
  const blockBanner = dataWebsite.data.attributes['block-banner'].attributes
  const blockServices = dataWebsite.data.attributes['block-layouts-services'].attributes
  const blockFramework = dataWebsite.data.attributes['block-model-framework'].attributes
  const blockFrameworkLogo = dataWebsite.data.attributes['block-framework-logo'].attributes
  const FooterBlock = dataWebsite.included.find(
    (block) => block.id === 'footer'
  );
  const blockInformative = dataWebsite.included.find(
    (block) => block.id === 'informative'
  );
  useEffect(() => {
    setInterval(() => {
      setIsfirstLoad(false)
    }, 5000);
    setInterval(() => {

      setLoadCanva(false)
    }, 5500);
  },)
  return (
    <React.Fragment>

<CursorDefault />
      {
        isFirstLoad ? <LoadPage textTitle={blockBanner.title} /> :
          <>
            <div className='content-block-header-blue' id='content-block-header-blue'>
             
             <div className="content-components-headers">
             <Header />
             <div className={`content-transition ${LoadCanva ? 'loading' : ''}`}>
                  {LoadCanva ? (
                    <div className='text-center content-load-spninner'>
                      <Spinner animation='grow' variant='light' />
                      <Spinner animation='grow' variant='light' />
                      <Spinner animation='grow' variant='light' />
                    </div>
                  ) :
             <>
                  <VideoComponent
                title={blockBanner.title}
                subtitle={blockBanner.description}
              />
              </>}
              </div>
            </div>
              </div>
              
            <div className='content-block-services' id='content-block-services'>
              <div className="content-component-services">
                <Title title={blockServices.title} />
                <LayoutsServices 
                data={blockServices.items}
                />
              </div>
            </div>
            <div className='content-block-framework' id='content-block-framework'>
              <OurFramework 
              title={blockFramework.title}
              description={blockFramework.description}
              subtitle={blockFramework.subtitile}
              subtitle_={blockFramework.subtitle_}
              data={blockFramework.items}
              multimedia_url={blockFramework.multimedia.url}
              />
            </div>
            <div className='content-block-framework-logo' id='content-block-framework-responsive'>
              <LayoutsTechnologies
              data={blockFrameworkLogo.items} />
            </div>
            <div className='content-block-workin-delfos' id='content-block-workin-delfos'>
            <ImageContainer
            data={blockInformative.attributes}
            />
            </div>
            <Footer
              title={FooterBlock.attributes.title}
              description={FooterBlock.attributes.description}
              email={FooterBlock.attributes.email}
              linktext={FooterBlock.attributes.link.text}
              linkUrl={FooterBlock.attributes.link.linkUrl}
              copyright={FooterBlock.attributes.copyright}
            />

          </>

      }
    </React.Fragment>
  )
}
