import React from 'react'
import '../components.css'
import { Col, Row } from 'react-bootstrap'
import imgLkd from '../../assets/img/components/LinkedIn.svg'
import tecl from '../../assets/img/components/teclado.png'
import ImgLkd from './ImgLinkelnd'
import { Link } from 'react-router-dom'
export const Linkelnd = ({ title, description, img, url }) => {
  
    const handleLikd= ()=>{
        window.open('https://www.linkedin.com/company/delfosti/mycompany/', '_blank');

    }
    return (
        <React.Fragment>

            <div className='container'>

                <div onClick={handleLikd} className='content-lkd text-center' style={{ textDecoration: 'none' }}>
                    <Row className='row-content-componet-lkd text-start' xl={12} md={12} sm={12} xs={12} >
                        <Col className='conten-img-likdn text-center' xl={6} md={12} sm={4} xs={4}>
                            <ImgLkd img={tecl} />
                        </Col>
                        <Col xl={6} md={12} sm={12} xs={12}>
                            <div className='row content-lkd-description'>
                                <Link to={url ? url : 'https://www.linkedin.com/company/delfosti/mycompany/'} style={{ textDecoration: 'none' }}>
                                    <h1 className='title-linkd' >
                                        {title ? title : 'Síguenos en Linked'}
                                        <img src={imgLkd} alt="lkd" className='img-link m-1 mt-0 pt-0' />
                                    </h1>
                                </Link>

                                <p className='subtitle-lkd'>
                                    {description ? description : 'Conoce las últimas novedades y oportunidades laborales.'}
                                </p>
                            </div>


                        </Col>
                    </Row>

                </div>
            </div>

        </React.Fragment>
    )
}
