import React, { useState } from 'react'
import { CursorDefault } from '../../components/Cursor/CursorDefault'
import { Header } from '../../components/Header'
import circle from '../../assets/notFound/circulo404.svg'
import number404 from '../../assets/notFound/number_404.svg'
import aislamiento from '../../assets/notFound/Modo_de_aislamiento.svg'
import arrow from '../../assets/img/components/Style=Filled.svg'
import arrowW from '../../assets/img/components/arrowwhite.svg'
import './notFound.css'
import { Button, Col, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
export const NotFound = () => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  return (
    <>

      <div className="content-page-not-found">
        <CursorDefault />
        <div className='container-fluid'>
          <Row xl={12} xxl={12} xs={12}>
            <Col  xl={12} xxl={12} md={12} xs={12}>
              <div className='block-content-header-error-404'>
              <Header />
                </div>
            </Col>
            <Col xl={6} xxl={6} md={12} xs={12}>
              <div className='content-information-404'>
              <div className='content-information-404-min'>
                  <img src={aislamiento} alt="aislamiento" className='image-aislamiento-min' />
                </div>
                <img src={number404} alt="number404" className='content-number-404' />
                <div className='content-text-information_404'>
                  <div className="title-information_404">
                    ¡Ups! Página no encontrada
                  </div>
                  <div className="description-information_404">
                    El enlace que seguiste probablemente esté roto o la página ha sido eliminada.
                  </div>
                </div>
                <Link to={'/'} className='content-button-information_404'>
                  <Button className='content-button-header'
                    variant={'outline-light border-2'}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}>

                    {
                      isHovered ?
                        <>
                          <span className='m-1 button-hover-none-blue-hover'>Volver al inicio</span>
                          <img src={arrow} className="image mb-1" alt="arrow" />
                        </> :
                        <>
                          <span className='m-1 button-hover-none-blue'>Volver al inicio</span>
                          <img src={arrowW} className="image mb-1" alt="arrow" />
                        </>

                    }

                  </Button>

                </Link>
              </div>
            </Col>
            <Col xl={6} xxl={6} md={12} xs={12} className='content-col-information-404-rigth'>
              <div className='content-not-fount-image' >
                <div className='content-information-404'>
                  <img src={aislamiento} alt="aislamiento" className='image-aislamiento' />
                </div>
              </div>
            </Col>
          </Row>
        </div>


      </div>

    </>
  )
}
