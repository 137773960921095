import React, { useEffect, useState } from 'react'
import { Header } from '../../components/Header'
import { VideoComponent } from '../../components/VideoComponent'
import { LoadPage } from '../../components/LoadPage'
import { Footer } from '../../components/Footer'
import { Linkelnd } from '../../components/Socials/Linkelnd'
import ImageContainer from '../../components/WorkingDelfos/ImageContainer'
import { Teams } from '../../components/OurFramework/Teams'
import { Col, Row, Spinner } from 'react-bootstrap'
import { dataApiWebsiteAbout } from '../../utils/DataApiWebsiteAbout'
import { CursorDefault } from '../../components/Cursor/CursorDefault'
export const About = () => {
  const [LoadCanva, setLoadCanva] = useState(true)
  const [isFirstLoad, setIsfirstLoad] = useState(true)
  const dataWebsite = dataApiWebsiteAbout;
  const blockBanner = dataWebsite.data.attributes['block-banner'].attributes
  const blockTeamsHistory = dataWebsite.data.attributes['block-teams-history'].attributes
  const blockTeamsCarrousel = dataWebsite.data.attributes['block-teams-carrousel'].attributes
  const linkedinBlock = dataWebsite.included.find(
    (block) => block.id === 'linkedin'
  );
  const FooterBlock = dataWebsite.included.find(
    (block) => block.id === 'footer'
  );
  const blockInformative = dataWebsite.included.find(
    (block) => block.id === 'informative'
  );
  useEffect(() => {
    setInterval(() => {
      setIsfirstLoad(false)
    }, 5000);
    setInterval(() => {

      setLoadCanva(false)
    }, 5500);
  },)
  return (
    <React.Fragment>
      <CursorDefault />
      {
        isFirstLoad ? <LoadPage textTitle={blockBanner.title} /> :
          <>
            <div className='content-block-header-blue'>
              <div className=''>
                <Header />
                <div className={`content-transition ${LoadCanva ? 'loading' : ''}`}>
                  {LoadCanva ? (
                    <div className='text-center content-load-spninner'>
                      <Spinner animation='grow' variant='light' />
                      <Spinner animation='grow' variant='light' />
                      <Spinner animation='grow' variant='light' />
                    </div>
                  ) : (
                    <VideoComponent
                      title={blockBanner.title}
                      subtitle={blockBanner.description}
                    />
                  )}
                </div>
              </div>
            </div>


            <div className='content-block-teams'>
              <div className='container content-component-title' id='content-component-title'>
                <Row xxl={10} md={10} xs={12}>
                  <div className=' content-title-component  title-responsive'>

                    <h1 className='titleObject '>
                      {blockTeamsHistory.title}
                      <div className='borderLineTitleBlue ' ></div>
                    </h1>
                    <p className=' description-teams ' >
                      {blockTeamsHistory.description}
                    </p>
                  </div>
                </Row>

              </div>
              <div className='content-block-teams-gallery'>
                <div className='container-img-teams ' >
                  <div className='container'>
                    <Row xxl={12} xl={12} md={12}>
                      <Col xxl={6} xl={6} md={12}>
                        <div className='content-img-team-first'>
                          <img src={blockTeamsHistory.multimedia[2].url} alt="teams" className='first-img-teams' />
                        </div>
                        <div className="content-img-team-seconds">
                          <img src={blockTeamsHistory.multimedia[0].url} alt="teams" className='second-img-teams' />
                        </div>


                      </Col>
                      <Col xxl={6} xl={6} md={12}>

                        <img src={blockTeamsHistory.multimedia[1].url} alt="teams" className=' thirth-img-teams' />
                      </Col>
                    </Row>

                  </div>
                </div>
              </div>
            </div>
            <div className='content-block-carrousel-teams'>
              <Teams
                data={blockTeamsCarrousel.items}
              />
            </div>


            <div className='content-block-component-working-delfos'>
              <ImageContainer
                data={blockInformative.attributes}
              />
            </div>
            <div className='content-block-component-linkld' id='content-block-component-linkld'>
              <Linkelnd
                title={linkedinBlock.attributes.title}
                url={linkedinBlock.attributes.url}
                description={linkedinBlock.attributes.description}
              />
            </div>
            <Footer
              title={FooterBlock.attributes.title}
              description={FooterBlock.attributes.description}
              email={FooterBlock.attributes.email}
              linktext={FooterBlock.attributes.link.text}
              linkUrl={FooterBlock.attributes.link.linkUrl}
              copyright={FooterBlock.attributes.copyright}
            />
          </>

      }
    </React.Fragment>
  )
}
