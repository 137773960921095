import React from 'react'
import logoSvg from '../assets/logo/delfosLogo.svg'
import logoEmail from '../assets/img/components/icon_email.svg'
import { Link } from 'react-router-dom'
import { Col, Row } from 'react-bootstrap'
export const Footer = ({title,description,email,linktext, linkUrl, copyright}) => {
    return (
        <footer  >

            <div className='container-fluid container-footer'>
                <Row className=' text-start content-footer' xxl={12}
                xl={12} md={12} xs={12}>
                    <h1 className='title-footer col-xl-12 col-mf-12 col-xs-12'>{title? title: 'Comunícate con nosotros'}</h1>
                    <p className='subtitle-footer col-xl-12 col-mf-12 col-xs-12'>{description ? description:'Escríbenos para comenzar el proyecto que tienes en mente.'}</p>
                    <p className=' subtitle-email-footer d-flex col-xl-12 col-mf-12 col-xs-12'>
                        <img src={logoEmail} alt="logo_delfosti" className='img-email' />
                        <a href="mailto:contacto@delfosti.com" className='noneDecoder'>{email? email:'contacto@delfosti.com'}</a></p>
                    <span className='d-block footer-text-linkd col-xl-12 col-mf-12 col-xs-12'>{`${linktext ? linktext : 'Síguenos en ' }`}<a className='lkd-text' href="https://www.linkedin.com/company/delfosti/mycompany/">{'LinkedIn'}</a></span>
                    <Row  xxl={12}
                xl={12} md={12} xs={12} className='  content-bottom-footer'>
                        <Col  xxl={6}
                xl={6} md={12} xs={12}>
                            <Link to="/" className='m-0 p-0'>
                                <img src={logoSvg} alt="logo_delfosti" className='logoWhite-delfosti' />
                            </Link>
                        </Col>
                        <Col className='footer-copyright 'xxl={6}
                xl={6} md={12} xs={12}>
                    {copyright ? copyright: '© 2023 Delfosti. Todos los derechos reservados.'}
                          
                        </Col>
                    </Row>

                </Row>
            </div>

        </footer>
    )
}
