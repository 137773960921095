import React, { useState } from 'react';
import { Col, Collapse, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom';

export const BlockObjetives = ({ data, title, description }) => {



    const [openItem, setOpenItem] = useState(null);

    const handleItemClick = (index) => {
        setOpenItem(openItem === index ? null : index);
    };


    return (
        <React.Fragment>
            <div className='component-objectives '>
                <div className='container'>
                    <Row xxl={12} md={12} xs={12}>
                        <Col xxl={6} md={6} xs={12}>
                            <div className='col-left-objetives'>
                                <h1 className='titleObject text-start'>
                                    {title}
                                    <div className='borderLineTitleBlue ' ></div>
                                </h1>
                                <p className='descriptionObject'>{description}</p>
                                <Link to={'/servicios'} className='btn-allServices' style={{ textDecoration: 'none', padding: '12px 16px 12px 16px', fontSize: '16px' }}>
                                    Ver los servicios
                                </Link>
                            </div>
                        </Col>
                        <Col xxl={6} md={6} xs={12}>
                            <div className='content-collapse-object'>
                                {data.map((item, index) => (
                                    <div key={index}>
                                        <div
                                            onClick={() => handleItemClick(index)}
                                            aria-controls={`item-collapse-text-${index}`}
                                            aria-expanded={openItem === index}
                                        >
                                            <div className={`row text-start content-title-collapse ${openItem === index ? 'title-collapse-active' : ''}`}>
                                                <div className='col-1 index-collapse' >
                                                    0{index + 1}
                                                </div>
                                                <div className='col title-collapse ' >
                                                    {item.title}
                                                </div>
                                            </div>

                                        </div>
                                        <Collapse in={openItem === index} className='card-collapse'>
                                            <div id={`item-collapse-text-${index}`} className=''>
                                                <p className='description-collapse'>{item.description}</p>
                                                {/* <a href={item.url} target="_blank" rel="noopener noreferrer" className='content-href' id='content-href'>
                                                    Ver más
                                                </a> */}
                                            </div>
                                        </Collapse>
                                    </div>
                                ))}
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </React.Fragment>
    )
}
