import React from 'react'
import icon from '../../assets/img/iconComillas.svg'

import { Col, Row } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';


export const LayoutsTeams = ({ img, name, job, testimony }) => {
    const isScreenLarge = useMediaQuery({ minWidth: 1235 });
    return (
        <>
            <div className='container-fluid card-carousel-team' id='card-carousel-team'>
                <Row xxl={12} xl={12} md={12} xs={12} sm={12} className=" content-row-teams-carruouser" >
                    <Col xxl={4} xl={12} md={12} xs={12} sm={12} className={`${isScreenLarge ? 'mainContainerContent' : 'mainConatinerFlex'}`} >
                        <div className='containerImgTeams'>
                            <img src={img} alt="teams" className='imgTeams' />
                            <div className='imgBorder'></div>
                            <div className='overlayDiv2'></div>
                            <div className='overlayDiv'></div>
                        </div>
                    </Col>
                    <Col xxl={8} xl={8} md={12} xs={12} sm={12} className={`${isScreenLarge ? 'content-data-testimony' : 'content-data-testimony-responsive'}`}>
                        <div className='card card-data-testimony' >
                            <div className='card-body m-0 p-0'>
                                <img src={icon} alt="icon" className='icon-testimony' />
                                <p className='data-testimony'>
                                    {testimony}
                                </p>
                            </div>
                            <div className='card-footer card-testimony-footer'>
                                <h6 className='card-footer-name'>
                                    {name}
                                </h6>
                                <p className='card-footer-job' >
                                    {job}                                </p>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div >
        </>
    )
}
