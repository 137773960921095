import {
  imgBChoose1,
  imgBChoose2,
  imgBChoose3,
  imgBChoose4,
} from "./image/imgBlockChoose.ts";
import {
  imgBriefcase1,
  imgBriefcase2,
  imgBriefcase3,
  imgBriefcase4,
  imgBriefcase5,
  imgBriefcase6,
  imgBriefcase7,
  imgBriefcase8,
  imgBriefcase9,
  imgBriefcase10,
  imgBriefcase11,
  imgBriefcase12,
  imgBriefcase13,
  imgBriefcase14,
  imgBriefcase15,
  imgBriefcase16,
  imgBriefcase17,
  imgBriefcase18,
  imgBriefcase19,
  imgBriefcase20,
} from "./image/imgBriefcase.ts";
 import {   bcp,
  efc,
  expertia,
  fcentral,
  honda,
  inteligio,
  jplaza,
  journeyou,
  Payki,
  Prudential,
  Prosegur,
  SF,
  nmviajes,
  izipay,
  luxury, } from "./image/ImgClients.ts";
  import video from '../assets/video/videoHome/Delfosweb3DHome.mp4'
export const dataApiWebsiteHome = {
  code: 200,
  status: "success",
  message: "Confirmed",
  data: {
    type: "page",
    id: "home",
    attributes: {
      "block-banner": {
        type: "block",
        id: "banner",
        attributes: {
          title: "Innovación tecnológica para impulsar tu empresa",
          multimedia: {
            //esto no es una imagen es una libreria para traer spline
            type: "canvas",
            url:video ,
            alt: "",
            title: "",
            embed: "",
          },
        },
      },
      "block-main": {
        type: "block",
        id: "main",
        attributes: {
          title: "Transformamos tu empresa",
          subtitle: "con oportunidades exponenciales.",
          items: [
            {
              title: "+4 años",
              description: "desarrollando ideas increíbles",
            },
            {
              title: "+25 clientes",
              description: "satisfechos con el resultado",
            },
            {
              title: "+80 expertos",
              description: "realizados con excelencia",
            },
          ],
        },
      },
      "block-objetives": {
        type: "block",
        id: "objetives",
        attributes: {
          title: "Alcanza tus objetivos",
          description:
            "Nuestro modelo operativo trabaja en alianza con la innovación y visión tecnológica. Nos enfocamos en obtener resultados tangibles mediante el acompañamiento y creación de soluciones personalizadas para cada empresa.",
          link: {
            text: "Ver los servicios",
            url: "",
          },
          items: [
            {
              title: "CX Blueprint ",
              description:
                "Entiende a tu usuario para diseñar la mejor experiencia, con interfaces de alto rendimiento.",
              url: "/servicios",
            },
            {
              title: "Technical blueprint ",
              description:
                "Definimos e implementamos las mejores tecnología para construir soluciones digitales de alto impacto",
              url: "/servicios",
            },
            {
              title: "Product and process hacking",
              description:
                "Retamos tus procesos y productos diseñando una solución digital capaz de ayudarte a alcanzar tus metas.",
              url: "/servicios",
            },
            {
              title: "Product fast tracking",
              description:
                "Te ayudamos a priorizar tareas desde una perspectiva técnica enfocada en el negocio, y así proponer estrategias que te permitan acelerar el proceso de ejecución de tu proyecto.",
              url: "/servicios",
            },
            {
              title: "Product sandbox",
              description:
                "Validamos tu hipótesis de valor con tu usuario y desarrollamos un MVP capaz de cubrir las necesidades básicas de tu producto.",
              url: "/servicios",
            },
            {
              title: "Digital factory ",
              description:
                "Hacemos realidad tu proyecto con especialistas capacitados en las últimas tecnologías que representan los más altos estándares de calidad y diseño.",
              url: "/servicios",
            },
            {
              title: "Staff augmentation ",
              description:
                "Suma a tu equipo de trabajo capacidades, con profesionales que te permitan ejecutar tu proyecto y ayudarte a alcanzar tus objetivos.",
              url: "/servicios",
            },
          ],
        },
      },
      "block-choose": {
        type: "block",
        id: "choose",
        attributes: {
          title: "Por qué elegirnos",
          description:
            "Queremos generar un cambio exponencial, haciendo de la tecnología parte del ADN de todas las empresas.",
          items: [
            {
              title: "Cercanía y co-creación",
              description:
                "Fomentamos la colaboración activa para crear soluciones personalizadas para  cada cliente.",
              multimedia: {
                type: "image",
                url: imgBChoose1,
                alt: "data1",
                title: "data1",
                embed: "",
              },
            },
            {
              title: "Flexibilidad y eficacia",
              description:
                "Nos adaptamos a los desafíos, brindando soluciones flexibles que se ajusten a los requerimientos específicos de cada negocio.",
              multimedia: {
                type: "image",
                url: imgBChoose2,
                alt: "data2",
                title: "data2",
                embed: "",
              },
            },
            {
              title: "Metodologías ágiles",
              description:
                "Aplicamos ciclos iterativos y transparentes que nos permiten  mejorar de forma constante.",
              multimedia: {
                type: "image",
                url: imgBChoose3,
                alt: "data3",
                title: "data3",
                embed: "",
              },
            },
            {
              title: "Innovadores",
              description:
                "Buscamos nuevas formas de superar los límites tecnológicos, para ofrecer soluciones creativas y vanguardistas.",
              multimedia: {
                type: "image",
                url: imgBChoose4,
                alt: "data4",
                title: "data4",
                embed: "",
              },
            },
          ],
        },
      },
    },
    relationships: {
      "block-gallery": {
        type: "block",
        id: "gallery",
        attributes: {
          items: [
            {
              name: "",
              type: "website",
              multimedia: {
                type: "image",
                url: imgBriefcase1,
                alt: "",
                title: "",
                embed: "",
              },
              url: "",
            },
            {
              name: "",
              type: "app",
              multimedia: {
                type: "image",
                url: imgBriefcase2,
                alt: "",
                title: "",
                embed: "",
              },
              url: "",
            },
            {
              name: "",
              type: "services",
              multimedia: {
                type: "image",
                url: imgBriefcase3,
                alt: "",
                title: "",
                embed: "",
              },
              url: "",
            },
            {
              name: "",
              type: "website",
              multimedia: {
                type: "image",
                url: imgBriefcase4,
                alt: "",
                title: "",
                embed: "",
              },
              url: "",
            },
            {
              name: "",
              type: "app",
              multimedia: {
                type: "image",
                url: imgBriefcase5,
                alt: "",
                title: "",
                embed: "",
              },
              url: "",
            },
            {
              name: "",
              type: "services",
              multimedia: {
                type: "image",
                url: imgBriefcase6,
                alt: "",
                title: "",
                embed: "",
              },
              url: "",
            },
            {
              name: "",
              type: "services",
              multimedia: {
                type: "image",
                url: imgBriefcase7,
                alt: "",
                title: "",
                embed: "",
              },
              url: "",
            },
            {
              name: "",
              type: "services",
              multimedia: {
                type: "image",
                url: imgBriefcase8,
                alt: "",
                title: "",
                embed: "",
              },
              url: "",
            },
            {
              name: "",
              type: "services",
              multimedia: {
                type: "image",
                url: imgBriefcase9,
                alt: "",
                title: "",
                embed: "",
              },
              url: "",
            },
            {
              name: "",
              type: "services",
              multimedia: {
                type: "image",
                url: imgBriefcase10,
                alt: "",
                title: "",
                embed: "",
              },
              url: "",
            },
            {
              name: "",
              type: "services",
              multimedia: {
                type: "image",
                url: imgBriefcase11,
                alt: "",
                title: "",
                embed: "",
              },
              url: "",
            },
            {
              name: "",
              type: "services",
              multimedia: {
                type: "image",
                url: imgBriefcase12,
                alt: "",
                title: "",
                embed: "",
              },
              url: "",
            },
            {
              name: "",
              type: "services",
              multimedia: {
                type: "image",
                url: imgBriefcase13,
                alt: "",
                title: "",
                embed: "",
              },
              url: "",
            },
            {
              name: "",
              type: "services",
              multimedia: {
                type: "image",
                url: imgBriefcase14,
                alt: "",
                title: "",
                embed: "",
              },
              url: "",
            },
            {
              name: "",
              type: "services",
              multimedia: {
                type: "image",
                url: imgBriefcase15,
                alt: "",
                title: "",
                embed: "",
              },
              url: "",
            },
            {
              name: "",
              type: "services",
              multimedia: {
                type: "image",
                url: imgBriefcase16,
                alt: "",
                title: "",
                embed: "",
              },
              url: "",
            },
            {
              name: "",
              type: "services",
              multimedia: {
                type: "image",
                url: imgBriefcase17,
                alt: "",
                title: "",
                embed: "",
              },
              url: "",
            },
            {
              name: "",
              type: "services",
              multimedia: {
                type: "image",
                url: imgBriefcase18,
                alt: "",
                title: "",
                embed: "",
              },
              url: "",
            },
            {
              name: "",
              type: "services",
              multimedia: {
                type: "image",
                url: imgBriefcase19,
                alt: "",
                title: "",
                embed: "",
              },
              url: "",
            },
       
           
          ],
        },
      },
      "block-customers": {
        type: "block",
        id: "customers",
        attributes: {
          title: "Nuestros clientes",
          items:  [
            {
                multimedia:
                {
                type:"image",
                alt:"",
                title:"",
                embed:"",
                url: Prudential
            }    
            },
            {
                multimedia:{
                    type:"image",
                    alt:"",
                    title:"",
                    embed:"",
                    url: expertia
                }
              
            }, {
                multimedia:{
                    type:"image",
                    alt:"",
                    title:"",
                    embed:"",
                    url: nmviajes
                }
              
            }, {
                multimedia:{
                    type:"image",
                    alt:"",
                    title:"",
                    embed:"",
                    url: SF
                }
              
            }, {
                multimedia:{
                    type:"image",
                    alt:"",
                    title:"",
                    embed:"",
                    url: fcentral
                }
               
            }, {
                multimedia:{
                    type:"image",
                    alt:"",
                    title:"",
                    embed:"",
                    url: inteligio
                }
               
            }, {
                multimedia:{
                    type:"image",
                    alt:"",
                    title:"",
                    embed:"",
                    url: jplaza
                }
             
            }, {
                multimedia:{
                    type:"image",
                    alt:"",
                    title:"",
                    embed:"",
                    url: journeyou
                }
              
            } ,{
                multimedia:{
                    type:"image",
                    alt:"",
                    title:"",
                    embed:"",
                    url: Payki
                }
               
            },{
                multimedia:{
                    type:"image",
                    alt:"",
                    title:"",
                    embed:"",
                    url: luxury
                }
              
            },  {
                multimedia:{
                    type:"image",
                    alt:"",
                    title:"",
                    embed:"",
                    url: bcp
                }
             
            }, {
                multimedia:{
                    type:"image",
                    alt:"",
                    title:"",
                    embed:"",
                    url: Prosegur
                }
              
            }, 
            {
                multimedia:{
                    type:"image",
                    alt:"",
                    title:"",
                    embed:"",
                    url: izipay
                }
            
            } ,{
                multimedia:{
                    type:"image",
                    alt:"",
                    title:"",
                    embed:"",
                    url: honda
                }
              
            },],
          link: {
            text: "Ver todos",
            url: "",
          },
        },
      },
    },
  },
  included: [
    {
      type: "block",
      id: "menu",
      attributes: {
        items: [
          {
            text: "Contáctenos",
            url: "",
          },
          {
            text: "Servicios",
            url: "",
          },
          {
            text: "Clientes",
            url: ""
          },
          {
            text: "Portafolio",
            url: "",
          },
          {
            text: "Conócenos",
            url: "",
          },
          {
            text: "Home",
            url: "",
            multimedia: {
              type: "image",
              url: "",
              alt: "",
              title: "",
              embed: "",
            },
          },
        ],
      },
    },
    {
      type: "block",
      id: "menu-mobile",
      attributes: {
        items: [
          {
            text: "Contáctenos",
            url: "",
          },
          {
            text: "Servicios",
            url: "",
          },
          {
            text: "Portafolio",
            url: "",
          },
          {
            text: "Conócenos",
            url: "",
          },
          {
            text: "Home",
            url: "",
            multimedia: {
              type: "image",
              url: "",
              alt: "",
              title: "",
              embed: "",
            },
          },
        ],
        "footer-menu": {
          title: "Escríbenos",
          email: "contacto@delfosti.com",
          link: {
            text: "Siguenos en",
            url: "https://www.linkedin.com/company/delfosti/mycompany/",
          },
          address: "Calle José Gálvez 692, Miraflores",
        },
      },
    },
    {
      type: "block",
      id: "informative",
      attributes: {
        title: "Trabajemos juntos",
        description:
          "Si estás buscando un alidado para hacer realidad tu proyecto, estamos para ayudarte.",
        link: {
          text: "Quiero saber más",
          url: "",
        },
      },
    },
    {
      type: "block",
      id: "linkedin",
      attributes: {
        title: "Siguenos en LinkedIn",
        description: "Conoce las novedades y oportunidades laborales ",
        link: {
          multimedia: {
            type: "image",
            url: "",
            alt: "",
            title: "",
            embed: "",
          },
          url: "",
        },
      },
    },
    {
      type: "block",
      id: "footer",
      attributes: {
        title: "Comunícate con nosotros",
        description:
          "Escríbenos para comenzar el proyecto que tienes en mente.",
        email: "contacto@delfosti.com",
        link: {
          text: "Siguenos en ",
          url: "https://www.linkedin.com/company/delfosti/mycompany/",
        },
        multimedia: {
          type: "image",
          url: "",
          alt: "",
          title: "",
          embed: "",
          link: "",
        },
        copyright: "© 2023 Delfosti. Todos los derechos reservados.",
      },
    },
  ],
  meta: {
    seo_title: "Delfosti",
    seo_description:
      "Transformamos tu empresa a través de la innovación para ofrecerte soluciones increíbles.",
    seo_keywords:
      "Delfosti, Desarrollo de Software, Soluciones Tecnológicas, Innovación Digital, Transformación Digital, Desarrollo de Aplicaciones, Soluciones Personalizadas, Ingeniería de Software, Consultoría Tecnológica, Programación Avanzada, Desarrollo Ágil, Soluciones Empresariales, Arquitectura de Software, Desarrollo a Medida, Experiencia de Usuario (UX), Soluciones Cloud, Integración de Sistemas, Consultoría en TI, Automatización de Procesos, Tecnologías Emergentes, Estrategias Digitales.",
    seo_url: "https://delfosti.com",
  },
  links: {},
};
