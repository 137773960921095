import React from 'react'
import { Title } from '../Title'
import '../components.css'
import img from '../../assets/img/graph.png'

export const OurFramework = ({data, title, description,subtitle,subtitle_,multimedia_url}) => {
  return (
        <React.Fragment>
            <div className='component-framwork' >
                <Title title={title} description={description} />
                <div className='container '>
                    <div className='containerFrmk'>
                        <div className='boxFrmk '>
                            <div className='descrptionFrmk'>
                                <h6 className='subtitleFrmk'>{subtitle}</h6>
                                <h3 className='titleFrmk'>{subtitle_}</h3>
                                <ul className='listFrmk'>
                                    {data.map((item, i) => (
                                        <li key={i} className='li-framework'>{item.title}</li>
                                    ))}
                                </ul>
                            </div>
                            <div className='grapImg'>
                                <img src={multimedia_url} alt="ux-desing" className='ux-img' />
                            </div>

                        </div>

                    </div>
                </div>
            </div>

        </React.Fragment>
    )
}
