import React, { useEffect, useRef, useState } from 'react'
import { LayoutsTeams } from '../LayoutsImagens/LayoutsTeams';
import { useMediaQuery } from 'react-responsive';
// import arrowNext from '../../assets/img/Size=Mobile, Orientation=Right, Estado=Default.svg'
// import arrowPrev from '../../assets/img/Size=Mobile, Orientation=Left, Estado=Default.png'
import arrowPrevDesk from '../../assets/img/components/arrowLeftWeb.svg'
import arrowNextDesk from '../../assets/img/components/Size=Web, Orientation=Right, Estado=Default.svg'
import arrowNextDeskActive from '../../assets/img/components/Size=Web, Orientation=Right, Estado=Press.svg'
import arrowPrevtDeskActive from '../../assets/img/components/Size=Web, Orientation=Left, Estado=Press.svg'
export const Teams = ({ data }) => {

  const [isHovered, setIsHovered] = useState(false);
  const [isHoveredNext, setIsHoveredNext] = useState(false);
  const isScreenLarge = useMediaQuery({ minWidth: 1235 });
  const refCarrousel = useRef();
  const [refNextVisible, setrefNextVisible] = useState(false)
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const handleMouseEnternext = () => {
    setIsHoveredNext(true);
  };

  const handleMouseLeavenext = () => {
    setIsHoveredNext(false);
  };
  useEffect(() => {
    if (!refNextVisible) {

      return
    } else {
      refCarrousel.current.click()
    }
    // detected rendering
  }, [refNextVisible])

  return (
    <React.Fragment>

      <div className='component-carousel-team' id='component-carousel-team' >

        <div className='container content-title-component  title-responsive'>
          <h1 className='titleObject '>
            {'Conoce al equipo'}
            <div className='borderLineTitleBlue ' ></div>
          </h1>
        </div>
        <div id="carouselTeams" data-interval="10000" className="carousel slide" data-bs-ride="carousel">
          <div className="carousel-inner">
            {data.map((team, i) => (
              <div className={`carousel-item ${i === 0 ? 'active' : ''}`} key={i}>
                <LayoutsTeams img={team.multimedia.url} job={team.jobs} name={team.name} testimony={team.testimony} />
              </div>
            ))}
            {!isScreenLarge ?
              ""
              :
              <>
                <a className="" href="#carouselTeams" data-bs-target="#carouselTeams" data-bs-slide="prev">
                  <div className="content-left-navigation-web">
                    <img src={isHovered ? arrowPrevtDeskActive : arrowPrevDesk} alt="left" className='navigation-left' onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave} />
                  </div>
                </a>
                <a className="carousel-control-next" href="#carouselTeams" role="button" data-bs-slide="next" ref={el => { refCarrousel.current = el; setrefNextVisible(!!el); }} >
                  <div className="content-rigth-navigation-web">
                    <img src={isHoveredNext ? arrowNextDeskActive : arrowNextDesk} alt="left" className='navigation-rigth' onMouseEnter={handleMouseEnternext}
                      onMouseLeave={handleMouseLeavenext} />
                  </div>
                </a>
              </>}
          </div>

          {!isScreenLarge ?
            <div className='content-arrow-carrousel-responsive'>
              <img alt='arrow-prev' className="arrow-prev-resposive" type="button" data-bs-target="#carouselTeams" data-bs-slide="prev" src={isHovered ? arrowPrevtDeskActive : arrowPrevDesk} onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave} />
              <img alt='arrow-next' className="arrow-next-resposive"  onMouseEnter={handleMouseEnternext}
                      onMouseLeave={handleMouseLeavenext} type="button" data-bs-target="#carouselTeams" data-bs-slide="next" src={isHoveredNext ? arrowNextDeskActive : arrowNextDesk} ref={el => { refCarrousel.current = el; setrefNextVisible(!!el); }} />
            </div> :
            ""}
        </div>

      </div>

    </React.Fragment>
  )
}
