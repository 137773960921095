import React, { useEffect, useState } from 'react';
import './components.css'

export const LoadPage = ({ textTitle }) => {
  const [marginTop, setMarginTop] = useState(0);

  // Function to calculate and set the margin-top value
  const centerTextLoad = () => {
    const screenHeight = window.innerHeight;
    const textLoadHeight = document.querySelector('.text-load').clientHeight;
    const newMarginTop = (screenHeight - textLoadHeight) / 2;
    setMarginTop(newMarginTop);
  };

  // Run the centerTextLoad function when the component mounts and when the window is resized
  useEffect(() => {
    centerTextLoad();
    window.addEventListener('resize', centerTextLoad);
    return () => {
      window.removeEventListener('resize', centerTextLoad);
    };
  }, []);
  return (
    <div className="content-load">
      <div className='content-blue1'></div>
      <div className='content-blue2'></div>
      <div className='content-blue3'>
        <h1 className='text-load' style={{ marginTop: `${marginTop}px` }}>
          {textTitle}
        </h1>


      </div>
    
  
    </div>
  );
};

