
import React, { useState, useEffect, useRef } from 'react';
import SwiperCore from 'swiper';
import { Pagination, Autoplay } from 'swiper/modules';
import { Col, Row } from 'react-bootstrap';
import { Swiper, SwiperSlide } from 'swiper/react';


SwiperCore.use([Autoplay, Pagination]);

export const BlockChoose = ({data}) => {
  const swiperRef = useRef(null);
  useEffect(() => {
    const swiperInstance = swiperRef.current.swiper;

    const autoplayInterval = setInterval(() => {
      if (swiperInstance && swiperInstance.isEnd) {
        swiperInstance.slideTo(0);
      } else {
        swiperInstance.slideNext();
      }
    }, 4000);

    return () => {
      clearInterval(autoplayInterval);
    };
  }, []);


  const [currentIndex, setCurrentIndex] = useState(0);
  const [isHovering, setIsHovering] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isHovering) {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % data.length);
      }
    }, 4000);

    return () => clearInterval(interval);
  }, [isHovering]);


  const handleItemHover = (index) => {
    setCurrentIndex(index);
  };

  return (
    <>
      <div className='container conetnt-component-block-choose-max '>
        <Row className='component-block-choose-max' xl={2} md={1} xs={1}>
          <Col xl={6} md={12} xs={12} className=''>
            <div className='content-gallery-block'>
              <div className='border-top-left'></div>
              <div className='border-top-rigth'></div>
              <img className='img-choose' src={data[currentIndex].multimedia.url} alt={data[currentIndex].title} />
            </div>
          </Col>
          <Col xl={6} md={12} xs={12} className=''>
            {data.map((data, index) => (
              <div
                key={index}
                className={`data-item ${currentIndex === index ? 'selected' : ''}`}
                onMouseEnter={() => {
                  setIsHovering(true);
                  handleItemHover(index);
                }}
                onMouseLeave={() => setIsHovering(false)}
              >
                <div className='content-data-item'>
                  <h1 className='title-blockChoose d-block'>{data.title}</h1>
                  <span className='description-blockChose'>{data.description}</span>
                </div>

              </div>
            ))}
          </Col>
        </Row>
      </div>
      <div className='conetnt-component-block-choose-min container'>
        <div className="component-block-choose-min">
          <Swiper
            ref={swiperRef}
            effect={'coverflow'}
            grabCursor={true}
            centeredSlides={true}
            loop={true}
            slidesPerView={'auto'}
            pagination={{
              dynamicBullets: true,
              clickable: true
            }}
            className="swiper_container"
          >
            {data.map((data, i) => (
              <SwiperSlide key={i}>
                <div className='content-bloc-choose-margin'>
                  <img src={data.multimedia.url} alt={data.multimedia.title} className='block-choose-img-carrousel' />
                  <div className='borde-left-responsive'></div>
                  <div className='borde-rigth-responsive'></div>
                  <div className='borde-top-rigth-responsive'></div>
                  <div className='borde-top-left-responsive'></div>
                  <div className='borde-bottom-left-responsive'></div>
                </div>

                <div className='content-carrousel-min mb-4'>
                  <div className='content-data-item'>
                    <h1 className='title-blockChoose d-block'>{data.title}</h1>
                    <span className='description-blockChose'>{data.description}</span>
                  </div>

                </div>
              </SwiperSlide>
            ))}
          </Swiper>

        </div>
      </div>
    </>
  );
};
