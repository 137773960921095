import React from 'react'
import { Row } from 'react-bootstrap'

export const Title = ({title, description}) => {
  return (
   <React.Fragment>
     <div className='container'>
         <Row xxl={10} md={10} xs={12}>
         <div className=' content-title-component  title-responsive'>
         <h1  className='titleObject '>
            {title}
            <div className='borderLineTitleBlue ' ></div>
          </h1>
          <p  className='descriptionTitle' >
            {description}
          </p>
         </div>
        
         </Row>
        </div>
   </React.Fragment>
  )
}
