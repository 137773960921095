import React, { useState } from 'react'
import { dataImg } from '../../utils/dataPortafolio';
import Masonry from 'react-responsive-masonry';
import close from '../../assets/img/components/Close-custon-modal.svg'
import arrowRigth from '../../assets/img/components/Arrow-next.svg'
import arrowLeft from '../../assets/img/components/Arrow-left.svg'
import { CursorCustom } from '../Cursor/CursorCustom';
import { CursorDefault } from '../Cursor/CursorDefault';

export const LayoutsBriefcase = ({ cursorType, onMouseEnter, onMouseLeave, data }) => {



  const [selectedTab, setSelectedTab] = useState('Ver todos');
  const [expandedImageIndex, setExpandedImageIndex] = useState(null);

  const expandImage = (index) => {
    setExpandedImageIndex(index);

  };

  const closeExpandedImage = () => {
    setExpandedImageIndex(null);
  };

  const prevImage = () => {
    if (expandedImageIndex > 0) {
      setExpandedImageIndex(expandedImageIndex - 1);
    }
  };

  const nextImage = () => {
    if (expandedImageIndex < dataImg.length - 1) {
      setExpandedImageIndex(expandedImageIndex + 1);
    }
  };
  const handleTabClick = (tab) => {
    setSelectedTab(tab);
  };


  return (
    <div className="content-component">
      <div onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave} className={`cursor-${cursorType}`} >
        <Masonry columnsCount={3} gutter='10px'
        >
          {data.map((img, index) => (
            <div  key={index} className={`image-wrapperz content-briefcase-max ${expandedImageIndex === index ? 'expanded' : ''
              }`}
              onClick={() => expandImage(index)}
            >
              <img
                className='portfolio-image'
                alt='swce'
                src={img.multimedia.url}
                style={{ width: "100%", display: "block" }} />
            </div>

          ))}
        </Masonry>
        <div className='content-briefcase-min'>

          {/* FILLTRO COMENTADO */}
         {/*  <div className='content-select-tabs'>
            <select className='form-select form-select-briefcase'
              value={selectedTab}
              onChange={(e) => handleTabClick(e.target.value)}
            >
              <option value="Ver todos">Ver todos</option>
              <option value="Proyectos web">Proyectos web</option>
              <option value="Aplicativos móviles">Aplicativos móviles</option>
              <option value="Sistemas internos">Sistemas internos</option>
              <option value="Integración">Imagen Integración</option>
              <option value="Soporte">Soporte</option>
            </select>
          </div> */}

          {data.map((img, index) => (
            <div key={index}
              className={`image-wrapperz ${expandedImageIndex === index ? 'expanded' : ''
                }`}
              onClick={() => expandImage(index)}
            >
              <img
                className='portfolio-image'
                alt='swce'
                src={img.multimedia.url}
                style={{ width: "100%", display: "block" }} />
            </div>

          ))}
        </div>

      </div>
      {expandedImageIndex !== null && (
        <div className='custom-modal-overlay'>
          <div className='custom-modal-content container-fluid'>
            {expandedImageIndex > 0 && (
              <div className="content-left-navigation">
                <img alt='previus' src={arrowLeft} className='modal-navigation-left' onClick={prevImage}>

                </img>
              </div>
            )}

            <img
              className='expanded-portfolio-image'
              alt='swce'
              src={dataImg[expandedImageIndex].src}
            />
            <img src={close} alt='close' className='custom-modal-close' onClick={closeExpandedImage}

            />

            {expandedImageIndex < dataImg.length - 1 && (
              <div className="content-rigth-navigation">
                <img alt='next-modal' src={arrowRigth} className='modal-navigation-rigth' onClick={nextImage} />
              </div>

            )}
          </div>
        </div>
      )}
    </div>

  );
};
