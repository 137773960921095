import React, { useEffect, useState } from 'react'
import { Footer } from '../../components/Footer'
import { VideoComponent } from '../../components/VideoComponent'
import { Header } from '../../components/Header'
import { LoadPage } from '../../components/LoadPage'
import ImageContainer from '../../components/WorkingDelfos/ImageContainer'
import { ListClients } from '../../components/Clients/ListClients'
import { Spinner } from 'react-bootstrap'
import { dataApiWebsiteClients } from '../../utils/DataApiWebsiteClients'
import { CursorDefault } from '../../components/Cursor/CursorDefault'

export const Clients = () => {
  const [LoadCanva, setLoadCanva] = useState(true)
  const [isFirstLoad, setIsfirstLoad] = useState(true);
  const dataWebsite = dataApiWebsiteClients;
  const blockBanner = dataWebsite.data.attributes['block-banner'].attributes
  const blockListClient = dataWebsite.data.attributes['block-list-clients'].attributes
  const FooterBlock = dataWebsite.included.find(
    (block) => block.id === 'footer'
  );
  const blockInformative = dataWebsite.included.find(
    (block) => block.id === 'informative'
  );
  useEffect(() => {
    setInterval(() => {
      setIsfirstLoad(false)
    }, 5000);
    setInterval(() => {

      setLoadCanva(false)
    }, 5500);


  },)
  return (
    <React.Fragment>
      <CursorDefault />
      {
        isFirstLoad ? <LoadPage textTitle={blockBanner.title} /> :
          <>
            <div className='content-block-header-clients'>
              <div className='content-component-header'>
                <Header />
                <div className={`content-transition ${LoadCanva ? 'loading' : ''}`}>
                  {LoadCanva ? (
                    <div className='text-center content-load-spninner'>
                      <Spinner animation='grow' variant='light' />
                      <Spinner animation='grow' variant='light' />
                      <Spinner animation='grow' variant='light' />
                    </div>
                  ) :
                    <>
                      <VideoComponent
                        title={blockBanner.title}
                        subtitle={blockBanner.description} />
                    </>}

                </div>
              </div>
            </div>
            <div className='content-block-list-clients' id='content-block-list-clients'>
              <ListClients
                data={blockListClient.items} />
            </div>

            <div className='content-block-working-delfos'>
              <ImageContainer
                data={blockInformative.attributes}
              />
            </div>

            <Footer
              title={FooterBlock.attributes.title}
              description={FooterBlock.attributes.description}
              email={FooterBlock.attributes.email}
              linktext={FooterBlock.attributes.link.text}
              linkUrl={FooterBlock.attributes.link.linkUrl}
              copyright={FooterBlock.attributes.copyright}
            />                    </>
      }
    </React.Fragment>
  )
}
