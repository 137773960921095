
import framework_img from '../assets/img/graph.png'
import aws from '../assets/Technologies/AWS.png'
import adobe from '../assets/Technologies/Adobe.png'
import angular from '../assets/Technologies/Angular.png'
import azure from '../assets/Technologies/Azure.png'
import figma from '../assets/Technologies/Figma.png'
import java from '../assets/Technologies/Java.png'
import magneto from '../assets/Technologies/Magento.png'
import vtex from '../assets/Technologies/VTEX.png'
import vue from '../assets/Technologies/Vue.png'
import Microsoft from '../assets/Technologies/Microsoft.svg'
import {  service, service1, service2, service3, service4, service5, service6 } from './image/imgServices.ts'
export const dataApiWebsiteServices = {
  "code": 200,
  "status": "success",
  "message": "Confirmed",
  "data": {
    "type": "page",
    "id": "services",
    "attributes": {
      "block-banner": {
        "type": "block",
        "id": "banner",
        "attributes": {
          "title": "Digitaliza tu negocio",
          "description": "Nos enfocamos en obtener resultados tangibles, mediante el acompañamiento y creación de soluciones personalizadas para cada cliente.",
          "multimedia": {
            "type": "video",
            "url": "",
            "alt": "",
            "title": "",
            "embed": ""
          }
        }
      },
      "block-layouts-services": {
        "type": "block",
        "attributes": {
          "title": "¿Que quieres lograr?",
          "description": "",
          "items": [
            {
              "title": 'Diseñar mejores experiencias',
              "description": 'Entiende a tu usuario para diseñar la mejor experiencia, con interfaces de alto rendimiento gracias a nuestro servicio de CX Blueprint.',
              "multimedia": {
                "type": "image",
                "url": service,
                "alt": "",
                "title": "",
                "embed": ""
              }
            },
            {
              "title": 'Implementar tecnologías de calidad',
              "description": 'Hacemos realidad tu proyecto con especialistas capacitados en las últimas tecnologías que representan los más altos estándares de calidad y diseño gracias a nuestra Digital factory.',
              "multimedia": {
                "type": "image",
                "url": service1,
                "alt": "",
                "title": "",
                "embed": ""
              }
            },
            {
              "title": 'Diseñar soluciones digitales',
              "description": 'Retamos tus procesos y productos diseñando una solución digital capaz de ayudarte a alcanzar tus metas con un Product and process hacking.',
              "multimedia": {
                "type": "image",
                "url": service2,
                "alt": "",
                "title": "",
                "embed": ""
              }
            },
            {
              "title": 'Acelerar la ejecución de un proyecto',
              "description": 'Te ayudamos a priorizar tareas desde una perspectiva técnica enfocada en el negocio, y así proponer estrategias que te permitan acelerar el proceso de ejecución de tu proyecto con nuestro servicio de Product fast tracking.',
              "multimedia": {
                "type": "image",
                "url": service3,
                "alt": "",
                "title": "",
                "embed": ""
              }
            },
            {
              "title": 'Desarrollar un MVP',
              "description": 'Suma a tu equipo de trabajo capacidades, con profesionales que te permitan ejecutar tu proyecto y ayudarte a alcanzar tus objetivos. Pregunta por nuestro Staff augmentation.',
              "multimedia": {
                "type": "image",
                "url": service4,
                "alt": "",
                "title": "",
                "embed": ""
              }
            },
            {
              "title": 'Desarrollar un producto digital',
              "description": 'Con nuestro servicio de Product sandbox validamos tu hipótesis de valor con tu usuario y desarrollamos un MVP capaz de cubrir las necesidades básicas de tu producto.',
              "multimedia": {
                "type": "image",
                "url": service5,
                "alt": "",
                "title": "",
                "embed": ""
              }
            },
            {
              "title": 'Sumar capacidades a mi equipo',
              "description": 'Suma a tu equipo de trabajo capacidades, con profesionales que te permitan ejecutar tu proyecto y ayudarte a alcanzar tus objetivos. Pregunta por nuestro Staff augmentation.',
              "multimedia": {
                "type": "image",
                "url": service6,
                "alt": "",
                "title": "",
                "embed": ""
              }
            }
          ]
        }
      },
      "block-model-framework": {
        "type": "block",
        "id": "model-framework",
        "attributes": {
          "title": "Nuestro framework",
          "description": "Nuestro modelo operativo trabaja en alianza con la innovación, visión y estrategia tecnológica.",
          "subtitile": "Nos enfocamos en diseñar una",
          "subtitle_": "digital formation model",
          "multimedia": {
            "type": "image",
            "url": framework_img,
            "alt": "",
            "title": "",
            "embed": ""
          },
          "items": [
            {
              "title": 'user experience'
            },
            {
              "title": 'offering & business model'
            },
            {
              "title": 'business process'
            },
            {
              "title": 'capacity building'
            }
          ]
        }
      },
      "block-framework-logo": {
        "type": "block",
        "id": "framework-logo",
        "attributes": {
          "items": [
            {
              "multimedia": {
                "type": "image",
                "url": Microsoft,
                "alt": "",
                "title": "",
                "embed": ""
              }
            }, {
              "multimedia": {
                "type": "image",
                "url": magneto,
                "alt": "",
                "title": "",
                "embed": ""
              }
            },
            {
              "multimedia": {
                "type": "image",
                "url": vue,
                "alt": "",
                "title": "",
                "embed": ""
              }
            },
            {
              "multimedia": {
                "type": "image",
                "url": angular,
                "alt": "",
                "title": "",
                "embed": ""
              }
            },
            {
              "multimedia": {
                "type": "image",
                "url": adobe,
                "alt": "",
                "title": "",
                "embed": ""
              }
            },
            {
              "multimedia": {
                "type": "image",
                "url": java,
                "alt": "",
                "title": "",
                "embed": ""
              }
            },
            {
              "multimedia": {
                "type": "image",
                "url": azure,
                "alt": "",
                "title": "",
                "embed": ""
              }
            },
            {
              "multimedia": {
                "type": "image",
                "url": vtex,
                "alt": "",
                "title": "",
                "embed": ""
              }
            },
            {
              "multimedia": {
                "type": "image",
                "url": figma,
                "alt": "",
                "title": "",
                "embed": ""
              }
            },
            {
              "multimedia": {
                "type": "image",
                "url": aws,
                "alt": "",
                "title": "",
                "embed": ""
              }
            }
          ]
        }
      },
    },
  },
  "included": [
    {
      "type": "block",
      "id": "menu",
      "attributes": {
        "items": [
          {
            "text": "Contáctenos",
            "url": ""
          },
          {
            "text": "Servicios",
            "url": ""
          },
          {
            "text": "Clientes",
            "url": ""
          },
          {
            "text": "Portafolio",
            "url": ""
          },
          {
            "text": "Conócenos",
            "url": ""
          },
          {
            "text": "Home",
            "url": "",
            "multimedia": {
              "type": "image",
              "url": "",
              "alt": "",
              "title": "",
              "embed": ""
            },
          }
        ]
      }
    },
    {
      "type": "block",
      "id": "menu-mobile",
      "attributes": {
        "items": [
          {
            "text": "Contáctenos",
            "url": ""
          },
          {
            "text": "Servicios",
            "url": ""
          },
          {
            "text": "Portafolio",
            "url": ""
          }, {
            "text": "Conócenos",
            "url": ""
          },
          {
            "text": "Home",
            "url": "",
            "multimedia": {
              "type": "image",
              "url": "",
              "alt": "",
              "title": "",
              "embed": ""
            },
          }
        ],
        "footer-menu": {
          "title": "Escríbenos",
          "email": "contacto@delfosti.com",
          "link": {
            "text": "Siguenos en ",
            "url": "https://www.linkedin.com/company/delfosti/mycompany/"
          },
          "address": "Calle José Gálvez 692, Miraflores",
        }
      }
    },
    {
      "type": "block",
      "id": "informative",
      "attributes": {
        "title": "Trabajemos juntos",
        "description": "Si estás buscando un alidado para hacer realidad tu proyecto, estamos para ayudarte.",
        "link": {
          "text": "Quiero saber más",
          "url": ""
        }
      }
    },
    {
      "type": "block",
      "id": "linkedin",
      "attributes": {
        "title": "Siguenos en LinkedIn",
        "description": "Conoce las novedades y oportunidades laborales ",
        "link": {
          "multimedia": {
            "type": "image",
            "url": "",
            "alt": "",
            "title": "",
            "embed": ""
          },
          "url": ""
        },
      }
    },
    {
      "type": "block",
      "id": "footer",
      "attributes": {
        "title": "Comunícate con nosotros",
        "description": "Escríbenos para comenzar el proyecto que tienes en mente.",
        "email": "contacto@delfosti.com",
        "link": {
          "text": "Siguenos en",
          "url": "https://www.linkedin.com/company/delfosti/mycompany/"
        },
        "multimedia": {
          "type": "image",
          "url": "",
          "alt": "",
          "title": "",
          "embed": "",
          'link': ""
        },
        "copyright": "© 2023 Delfosti. Todos los derechos reservados."
      }
    }
  ],
  "meta": {
    "seo_title": "Delfosti",
    "seo_description": "Transformamos tu empresa a través de la innovación para ofrecerte soluciones increíbles.",
    "seo_keywords": "Delfosti, Desarrollo de Software, Soluciones Tecnológicas, Innovación Digital, Transformación Digital, Desarrollo de Aplicaciones, Soluciones Personalizadas, Ingeniería de Software, Consultoría Tecnológica, Programación Avanzada, Desarrollo Ágil, Soluciones Empresariales, Arquitectura de Software, Desarrollo a Medida, Experiencia de Usuario (UX), Soluciones Cloud, Integración de Sistemas, Consultoría en TI, Automatización de Procesos, Tecnologías Emergentes, Estrategias Digitales.",
    "seo_url": "https://delfosti.com"
  },
  "links": {}
}