import React from 'react';




const ImgLkd = ({ img }) => {
  return (
    // <div >
    //   <div style={whiteDivStyle}>

    //     <div style={blueDiv1Style}>
    //       <div>
    //         <img
    //           src={img} // Reemplaza esto con la ruta de tu imagen
    //           alt="Imagen"
    //           style={{ marginTop: '16px', marginLeft: '-204px' }}
    //         />

    //         <div style={blueDiv2Style}></div>

    //       </div>

    //     </div>


    //   </div>
    // </div>
    <div className='containerLkd'>
      <div className='border-blue-top-large'>

      </div>
      <div  className='border-blue-bottom-large'>
        
      </div>
      <div className='opacityLkd'>
        
      </div>
    <img src={img} alt="lkd" className='lkdImg' />
    </div>
  );
};

export default ImgLkd;
