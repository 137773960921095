import React, { useState, useEffect } from "react";
import './clients.css'
import { Button, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

export const ClientsLogo = ({ data }) => {
  const isScreenLarge = useMediaQuery({ minWidth: 901 });
  const maxVisibleImages = isScreenLarge ? 10 : 8;
  const transitionDuration =1400;

  function getRandomImage(data, excludeImages) {
    const availableImages = data.filter(image => !excludeImages.includes(image));
    const randomIndex = Math.floor(Math.random() * availableImages.length);
    return availableImages[randomIndex];
  }


  const [visibleImages, setVisibleImages] = useState([]);

  useEffect(() => {
    const initialImages = data.slice(0, maxVisibleImages);
    setVisibleImages(initialImages);
  }, [data, maxVisibleImages]);

  
  useEffect(() => {
    const interval = setInterval(() => {
      setVisibleImages(prevVisibleImages => {
        if (prevVisibleImages.length >= maxVisibleImages) {
          const newVisibleImages = [...prevVisibleImages];
          const randomIndex1 = Math.floor(Math.random() * maxVisibleImages);
          let randomIndex2 = Math.floor(Math.random() * maxVisibleImages);
          
          // Ensure randomIndex2 is different from randomIndex1
          while (randomIndex2 === randomIndex1) {
            randomIndex2 = Math.floor(Math.random() * maxVisibleImages);
          }
          
          // Apply the hidden class to the data to fade them out
          newVisibleImages[randomIndex1] = { ...newVisibleImages[randomIndex1], hidden: true };
          newVisibleImages[randomIndex2] = { ...newVisibleImages[randomIndex2], hidden: true };
          
          setTimeout(() => {
            newVisibleImages[randomIndex1] = getRandomImage(data, newVisibleImages);
            newVisibleImages[randomIndex2] = getRandomImage(data, newVisibleImages);
            newVisibleImages[randomIndex1].hidden = false; // Remove hidden class to fade them in
            newVisibleImages[randomIndex2].hidden = false;
            setVisibleImages(newVisibleImages);
          }, 1000); // Wait for the fade out transition duration
          
          return newVisibleImages;
        } else {
          return [...prevVisibleImages, getRandomImage(data, prevVisibleImages)];
        }
      });
    }, transitionDuration);

    return () => clearInterval(interval);
  }, [maxVisibleImages, data]);

    return (
    <div className="container container-log-cliente">
      <div className='content-component-client-logo'>
      <Row xxl={10} md={10} xs={12}>
        <div className="content-title-client">
          <h1 className='titleObject '>
            {'Nuestros clientes'}
            <div className='borderLineTitleBlue ' ></div>
          </h1>
        </div>
        </Row>

        <Row className="justify-content-md-center component-logo-client" xs={2} md={3} xl={5}>
        {visibleImages.map((image, index) => (
          <Col key={index} className=" content-client-img cols-xl-2 cols-md-4 cols-xs-6">
            <img
              src={image.multimedia.url}
              alt={`Imagen ${index}`}
              className={`clientImg ${image.hidden ? "hidden" : ""}`} // Apply hidden class conditionally
            />
          </Col>
        ))}
      </Row>
        <div className="content-btn-client">
          <Link to={'/clientes'} >
            <Button className='btn-client' style={{ textTransform: 'none' }}>
              Ver todos
            </Button>
          </Link>

        </div>
      </div>
    </div>

  )
}
