// components/ImageLayout.js
import React, { useState } from 'react';
import { Button, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Masonry from 'react-responsive-masonry';
import { useMediaQuery } from 'react-responsive';
import close from '../../assets/img/components/Close-custon-modal.svg'
import arrowRigth from '../../assets/img/components/Arrow-next.svg'
import arrowLeft from '../../assets/img/components/Arrow-left.svg'

export const ImageLayout = ({ cursorType, onMouseEnter, onMouseLeave, data }) => {
  const galleryItems = data.data.relationships['block-gallery'].attributes.items;

  const isScreenLarge = useMediaQuery({ minWidth: 770 });

  const [expandedImageIndex, setExpandedImageIndex] = useState(null);

  const expandImage = (index) => {
    setExpandedImageIndex(index);

  };

  const closeExpandedImage = () => {
    setExpandedImageIndex(null);
  };

  const prevImage = () => {
    if (expandedImageIndex > 0) {
      setExpandedImageIndex(expandedImageIndex - 1);
    }
  };

  const nextImage = () => {
    if (expandedImageIndex < galleryItems.length - 1) {
      setExpandedImageIndex(expandedImageIndex + 1);
    }
  };

  return (
    <React.Fragment>
      <div className={`cursor-${cursorType} ${isScreenLarge ? 'container' : 'container-fluid'
        } content-galerry component-gallery-projects-max`} id='component-gallery-projects-max' onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave} >
        <Masonry columnsCount={3} gutter='10px'>
          {galleryItems.map((img, index) => (
            <div
              key={index}
              className={`image-wrapperz ${expandedImageIndex === index ? 'expanded' : ''
                }`}
              onClick={() => expandImage(index)}
            >
              <img className='portfolio-image' alt='swce' src={img.multimedia.url} />
            </div>
          ))}
        </Masonry>
      </div>
      <div className='content-gallery-responsive' id='component-gallery-projects-min'>

        <Row xl={12} md={12} xs={12} >
          {galleryItems.map((img, index) => (
            <div
              key={index}
              className={`image-wrapperz ${expandedImageIndex === index ? 'expanded' : ''
                }`}
              onClick={() => expandImage(index)}
            >
              <img
                className='portfolio-image'
                alt='swce'
                src={img.multimedia.url}
              />
            </div>

          ))}

        </Row>

      </div>

      {expandedImageIndex !== null && (
        <div className='custom-modal-overlay'>
          <div className='custom-modal-content container-fluid'>
            {expandedImageIndex > 0 && (
              <div className="content-left-navigation">
                <img alt='previus' src={arrowLeft} className='modal-navigation-left' onClick={prevImage}>

                </img>
              </div>

            )}

            <img
              className='expanded-portfolio-image'
              alt='swce'
              src={galleryItems[expandedImageIndex].multimedia.url}
            />
            <img src={close} alt='close' className='custom-modal-close' onClick={closeExpandedImage}

            />

            {expandedImageIndex < galleryItems.length - 1 && (
              <div className="content-rigth-navigation">
                <img alt='next-modal' src={arrowRigth} className='modal-navigation-rigth' onClick={nextImage} />
              </div>

            )}
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

