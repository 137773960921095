import React from 'react'
import { Col, NavLink, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import logoEmail from '../assets/img/components/icon_email.svg'
import logoSvgWhite from '../assets/logo/logoWhite.svg'
import closeImg from '../assets/logo/close.png'
import { useMediaQuery } from 'react-responsive'
export const NavbarResponsive = () => {
    const isScreenLarge = useMediaQuery({ minHeight: 482 });

    return (
      <div className="content-navbar-responsive-mobile">
          <div className=" container">
        
                <Row xl={12} md={12} xs={12} className='content-colum-resosive-navbar'>
                    <Col xl={6} md={6} xs={6}>
                    <NavLink className='content-logo-header-responsiv text-start border-0' eventKey={1} as={Link} to="/"> <img
                        src={logoSvgWhite} alt="logo_delfosti"  className='logoDelfos'/>
                    </NavLink>
                    </Col>
                    <Col xl={6} md={6} xs={6}>
                    <span className='text-end '
                        data-mdb-toggle="collapse"
                        data-mdb-target="#navbar-bullets"
                        aria-controls="navbar-bullets"
                        aria-expanded="false"
                        aria-label="Toggle navigation " >
                        <img src={closeImg} alt="close" className='close-menu' />
                    </span>
                    </Col>
                  
                </Row>

                <div className='content-body-navbar'>
                    {/* ----------------comentado Boton conocenos--------------------- */}

                    <NavLink className='text-content-navbar' eventKey={2} as={Link} to="/nosotros">Conócenos</NavLink>
                    <NavLink className='text-content-navbar' eventKey={3} as={Link} to="/servicios" >Servicios</NavLink>
                    <NavLink className='text-content-navbar' eventKey={4} as={Link} to="/clientes" >Clientes</NavLink>
                    <NavLink className='text-content-navbar' eventKey={5} as={Link} to="/portafolio" >Portafolio</NavLink>

                </div>
      
            <div className={`card-footer-navbar ${!isScreenLarge ? 'scrollable' : ''}`}>
                <span className='d-block text-navbar-footer'>Escríbenos</span>
                <p className=' subtitle-email-footer d-flex'>
                    <img src={logoEmail} alt="logo_delfosti" className='mt-0 img-responsive-email' />
                    <a href="mailto:contacto@delfosti.com" className='noneDecoder text-email-footer'>contacto@delfosti.com</a></p>
                <span className='d-block text-navbar-footer'>Síguenos en <a className='lkd-text' href="https://www.linkedin.com/company/delfosti/mycompany/">LinkedIn</a></span>
                <span className='d-block  text-navbar-footer'>Calle José Gálvez 692, Miraflores</span>
            </div>
        </div>
      </div>
    )
}
